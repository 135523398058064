import { useSelector } from 'react-redux';
import Header from '../components/header';
import { ChildrenProps } from '../types';

const DefaultLayout = ({ children }: ChildrenProps) => {
  const { showDrawer } = useSelector((state: any) => state.customerReducer);
  return (
    <div
      className="wrapper"
      style={
        showDrawer
          ? {
              display: 'none',
            }
          : {}
      }
    >
      <Header />
      <div className="drawer-wrapper"></div>
      <>{children}</>
    </div>
  );
};

export default DefaultLayout;
