export const repeatSettingItems = [
    {
        label: "毎週",
        value: 1
    },
    {
        label: "隔週",
        value: 2
    },
    {
        label: "毎月",
        value: 3
    },
]