import config from '../../../../../config';
import image from '../../../../../assets/images/index';
import HelmetComp from '../../../../../components/helmet/Helmet';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { title } from '../../../../../utils/matchingTitle';
import { getLocalStorage } from '../../../../../helper/common';

const CompleteSendEmailForgotPassword = ({ changePassword = false }) => {
  const navigate: NavigateFunction = useNavigate();
  const user = getLocalStorage('usrfpw');

  return (
    <>
      <HelmetComp title={title.customerSendEmailChangePassword} />
      <div className="complete-container container-630">
        <h1 className="title">{'パスワード変更依頼完了'}</h1>
        <p className="description">
          ご登録いただいたメールアドレスに、
          <br />
          本登録のご案内を送信しました。
          <br />
          メールに記載されている認証用URLをクリックして
          <br />
          アカウントの本登録を行ってください。
        </p>
        <div className="attention">
          <div className="attention-title">
            <img src={image.iconAttention} alt="Error" />

            <h1>メールが届かない場合</h1>
          </div>
          <div className="attention-content">
            <span>迷惑メールフォルダ等に振り分けられていないかご確認ください。</span>
            <span>メールが届かない場合、入力したメールアドレスが間違っている可能</span>
            <span>性がございます。再度、新規会員登録を行ってください。</span>
          </div>
        </div>
        <button
          className="btn cr-allow"
          onClick={(): void => {
            user ? navigate(config.routes.loginCustomer) : navigate(config.routes.login);
          }}
        >
          TOPページへ戻る
        </button>
      </div>
    </>
  );
};

export default CompleteSendEmailForgotPassword;
