export const castHourArrayItems = [
  {
    id: 1,
    title: '09:00 ~ 10:00',
    active: false,
    isActive: false,
    isParent: false,
    blocked: false,
    value: {
      start_time: 1,
      end_time: 2,
    },
  },
  {
    id: 2,
    title: '10:00 ~ 11:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 2,
      end_time: 3,
    },
  },
  {
    id: 3,
    title: '11:00 ~ 12:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 3,
      end_time: 4,
    },
  },
  {
    id: 4,
    title: '12:00 ~ 13:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 4,
      end_time: 5,
    },
  },
  {
    id: 5,
    title: '13:00 ~ 14:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 5,
      end_time: 6,
    },
  },
  {
    id: 6,
    title: '14:00 ~ 15:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 6,
      end_time: 7,
    },
  },
  {
    id: 7,
    title: '15:00 ~ 16:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 7,
      end_time: 8,
    },
  },
  {
    id: 8,
    title: '16:00 ~ 17:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 8,
      end_time: 9,
    },
  },
  {
    id: 9,
    title: '17:00 ~ 18:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 9,
      end_time: 10,
    },
  },
  {
    id: 10,
    title: '18:00 ~ 19:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 10,
      end_time: 11,
    },
  },
  {
    id: 11,
    title: '19:00 ~ 20:00',
    active: false,
    isParent: false,
    blocked: false,
    isActive: false,

    value: {
      start_time: 11,
      end_time: 12,
    },
  },
];
