export const RankUserItems: any = [
  {
    key: 0,
    label: 'ブラックリスト',
  },
  {
    key: 1,
    label: 'ダイヤ',
  },
  {
    key: 2,
    label: 'プラチナ',
  },
  {
    key: 3,
    label: 'ゴールド',
  },
  {
    key: 4,
    label: 'シルバー',
  },
];
