export const images = {
  iconSad: require('../images/svg/icon-sad.svg').default,
  iconUser: require('../images/svg/iconUser.svg').default,
  iconBank: require('../images/svg/iconBank.svg').default,
  iconStar: require('../images/svg/iconStar.svg').default,
  iconDove: require('../images/svg/iconEmailSuccess.svg').default,
  iconMail: require('../images/svg/icon-mail.svg').default,
  iconCvv: require('../images/mockup/iconCvv.svg').default,
  logoAdmin: require('../images/svg/logoAdmin.svg').default,
  iconPan: require('../images/mockup/icon-pan.svg').default,
  infoIcon: require('../images/mockup/iconInfo.svg').default,
  iconClose: require('../images/svg/icon-close.svg').default,
  iconPhone: require('../images/svg/icon-phone.svg').default,
  iconPlus: require('../images/mockup/icon-plus.svg').default,
  iconBell: require('../images/mockup/icon-bell.svg').default,
  iconMinus: require('../images/mockup/iconMinus.svg').default,
  iconSchedule: require('../images/svg/icon-show.svg').default,
  iconClock: require('../images/mockup/icon-time.svg').default,
  iconSuccess: require('../images/svg/iconSuccess.svg').default,
  iconSetting: require('../images/svg/iconSetting.svg').default,
  iconBlock: require('../images/mockup/icon-block.svg').default,
  iconWarning: require('../images/svg/warningIcon.svg').default,
  iconLogoBank: require('../images/mockup/iconBank.svg').default,
  iconJCB: require('../images/svg/iconLogo/iconJCB.svg').default,
  iconSearch: require('../images/mockup/iconSearch.svg').default,
  iconQuestion: require('../images/svg/iconQuestion.svg').default,
  iconDocument: require('../images/svg/iconDocument.svg').default,
  iconUserMini: require('../images/svg/iconUserMini.svg').default,
  iconClean: require('../images/mockup/cleanService.svg').default,
  iconVisa: require('../images/svg/iconLogo/iconVisa.svg').default,
  iconHourglass: require('../images/mockup/hourglass.svg').default,
  iconRequest: require('../images/mockup/icon-request.svg').default,
  iconAttention: require('../images/svg/iconAttention.svg').default,
  iconInforCast: require('../images/svg/iconInforCast.svg').default,
  iconAdminSelect: require('../images/svg/admin-select.svg').default,
  iconArrowRight: require('../images/svg/iconArrowRight.svg').default,
  iconArrowLeft: require('../images/svg/icon-arrow-left.svg').default,
  iconCalendarCast: require('../images/svg/iconCalendar.svg').default,
  iconMenuHeader: require('../images/svg/iconMenuHeader.svg').default,
  iconCalendar: require('../images/mockup/icon-calendar-2.svg').default,
  iconCVVInstruct: require('../images/svg/iconCVVInstruct.svg').default,
  iconPlusExtend: require('../images/mockup/iconPlusExtend.svg').default,
  iconDiscover: require('../images/svg/iconLogo/iconDiscover.svg').default,
  iconRequestList: require('../images/mockup/icon-request-list.svg').default,
  iconArrowSelectTag: require('../images/mockup/arrowSelectTag.svg').default,
  iconMasterCard: require('../images/svg/iconLogo/iconMasterCard.svg').default,
  iconDinersClub: require('../images/svg/iconLogo/iconDinersClub.svg').default,
  iconArrowStatusBarUser: require('../images/svg/Arrow-active-user.svg').default,
  iconAmericanExpress: require('../images/svg/iconLogo/iconAmericanExpress.svg').default,
  iconLogo: require('../images/svg/iconLogo.svg').default,
  logoLoginNew: require('../images/svg/logoLoginNew.svg').default,
  iconSurveyDone: require('../images/svg/iconSurveyDone.svg').default,
  iconMyPage: require('../images/svg/iconMyPage.svg').default,
  iconClean2: require('../images/mockup/iconClean2.svg').default,
  iconBlock2: require('../images/mockup/iconBlock2.svg').default,
  iconPan2: require('../images/mockup/iconPan2.svg').default,
  iconRedStar: require('../images/svg/red-star.svg').default,
  iconQues: require('../images/svg/sc_icon.svg').default,
};

export default images;
