/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import config from '../../../config';
import useNotification from 'antd/es/notification/useNotification';

import type { ColumnsType } from 'antd/es/table';

import { CSVLink } from 'react-csv';
import { DeleteOutlined } from '@ant-design/icons';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tableLoading } from '../customer/customerList';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Modal, Pagination, Space, Table } from 'antd';
import { serviceItems } from '../../../utils/customerServiceItems';
import { hourScheduleItems } from '../../../utils/hourScheduleItems';
import { alertFail, alertSuccess, formatCash } from '../../../helper/common';
import { deleteBookingMatching, exportCSVMatching, getListMatching } from '../../../redux/services/adminSlice';

import adminApi from '../../../api/adminApi/adminApi';
import ExportCSV from '../../../components/ExportCSV/ExportCSV';

const DEFAULT_PAGE = '1';

const BookingList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [api, showPopup]: any = useNotification();

  // HOOKS STATE
  const [idMatching, setIdMatching]: [any, React.Dispatch<any>] = useState();
  const [isModalOpen, setIsModalOpen]: [boolean, React.Dispatch<any>] = useState(false);
  const [dataCSV, setDataCSV] = useState<any>([]);

  // PARAMSS
  let [searchParams, setSearchParams]: any = useSearchParams({
    page: DEFAULT_PAGE,
  });

  // REDUCERS
  const { listMatching, loading, listDataCSVMatching }: any = useSelector((state: any) => state.adminReducer);

  // HOOK EFFECT
  useEffect(() => {
    dispatch(exportCSVMatching());
  }, []);

  useEffect(() => {
    if (searchParams.get('page') === null) {
      setSearchParams({ page: DEFAULT_PAGE });
    }
  }, [searchParams, setSearchParams]);

  // GET MATCHING WITH PARAMS
  useEffect((): void => {
    dispatch(getListMatching(searchParams));
  }, [dispatch, searchParams]);

  // HANDLE DATA EXPORT CSV

  useEffect(() => {
    const dataExportCSV =
      listDataCSVMatching?.map((matching: any) => {
        return _.values({
          booking_id: matching?.id,
          order_id: matching?.order_id,
          service_id: matching?.service_id || '',
          date: matching?.date || '',
          hour: matching?.hour,
          user_name: matching?.user_name
            ? `${matching?.user_name}${matching?.user_id ? `-A${matching?.user_id}` : ''}`
            : '',
          address: matching?.address,
          cast_name:
            matching?.cast_name !== null
              ? `${matching?.cast_name}${matching?.cast_id ? `-B${matching?.cast_id}` : ''}`
              : '',
          assign: formatCash(matching?.assign?.toString()) ?? 0,
          cancel: matching?.cancel ?? 0,
          coupon: formatCash(matching?.coupon?.toString()) ?? 0,
          total_price: matching?.cancel !== 0 ? 0 : formatCash(matching?.total_price?.toString()) ?? 0,
          status_matching: matching?.status_matching === 0 ? 'アサイン中' : '確定',
          request_description: matching?.request_description || '',
          a: matching?.status?.a ?? 0,
          b: matching?.status?.b ?? 0,
          c: matching?.status?.c ?? 0,
          d: matching?.status?.d ?? 0,
          e: matching?.status?.e ?? 0,
        });
      }) || [];

    // Handle data export CSV
    const tmpDataExportCSV = [
      [
        '予約ID',
        '受注ID',
        '依頼内容',
        '依頼日',
        '依頼時間',
        '依頼者',
        '住所',
        'キャスト',
        '指名キャスト',
        'キャンセル',
        'クーポン利用',
        '暫定料金',
        'ステータス',
        '備考',
        'A',
        'B',
        'C',
        'D',
        'E',
      ],
      ...dataExportCSV,
    ];
    setDataCSV(tmpDataExportCSV);
  }, [listDataCSVMatching]);

  // HANDLE PAGINATION
  const handlePagination = (page: number) => {
    const updatedParams: any = { page: page.toString() };
    setSearchParams(updatedParams);
  };

  // HANDLE CONVERT DATE
  const convertDate = (date: any) => {
    return date?.replace(/-/g, '/');
  };

  // FORMAT LIST MATCHING
  const formattedListMatching: any =
    listMatching?.length !== 0 && listMatching !== undefined
      ? listMatching?.data?.map((matching: any, index: any) => ({
          ...matching,
          //   service_id: serviceItems[matching?.service_id - 1]?.label,
          service_id: serviceItems.filter((itemService) => {
            return itemService?.value === matching?.service_id;
          })[0]?.label,
          hour: hourScheduleItems[matching?.hour - 2]?.title,
          key: index + 1,
        }))
      : [];

  // SHOW MODAL
  const showModal = (id: any): void => {
    setIsModalOpen(true);
    setIdMatching(id);
  };

  // HANDLE DELETE MATCHING
  const handleDeleteMatching = async (): Promise<any> => {
    try {
      const res: any = await dispatch(deleteBookingMatching(idMatching));
      if (res?.payload?.status === 'success') {
        dispatch(getListMatching(searchParams));
        alertSuccess(api, '変更が完了しました。');
        setIsModalOpen(false);
      } else {
        alertFail(api, '変更に失敗しました。');
      }
    } catch (error) {}
  };

  // CLOSE MODAL
  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  // Handle checkbox on table
  const onCheckBox = async (e: any, record: any) => {
    try {
      const dataSubmit = {
        customer_service_id: record.id,
        [e.target.name.toLowerCase()]: e.target.checked ? 1 : 0,
      };

      const res: any = await adminApi.saveStatusBookingList(dataSubmit);
      if (res?.status === 'success') {
        alertSuccess(api, '変更が出来ました。');
        dispatch(getListMatching(searchParams));
        dispatch(exportCSVMatching());
      } else {
        alertFail(api, '変更が失敗しました。');
      }
    } catch (error) {
      alertFail(api, '変更が失敗しました。');
    }
  };

  // COLUMN TABLES
  const columns: ColumnsType<any> = [
    {
      key: 1,
      title: '予約ID',
      dataIndex: 'id',
      width: '4%',
      align: 'center',
    },
    {
      key: 2,
      title: '受注ID',
      dataIndex: 'order_id',
      width: '5%',
    },
    {
      key: 3,
      title: '依頼内容',
      dataIndex: 'service_id',
      width: '7%',
      render: (text, _) => <span>{text}</span>,
    },
    {
      key: 4,
      title: '依頼日',
      dataIndex: 'date',
      width: '8%',
      render: (text, _) => <span>{convertDate(text)}</span>,
    },
    {
      key: 5,
      title: '依頼時間',
      dataIndex: 'hour',
      width: '4%',
      render: (text, _) => <span>{text}</span>,
    },
    {
      key: 6,
      title: '依頼者',
      dataIndex: 'user_name',
      width: '8%',
      render: (_, record) => (
        <>
          {record?.user_id !== null ? (
            <>
              {record?.is_deleted_user === 0 ? (
                <Link to={`${config.routes.adminCustomerDetail}/${record?.user_id}`}>
                  {record?.user_name === null ? (
                    <span className="underline">{record?.user_id ? `A${record?.user_id}` : ''}</span>
                  ) : (
                    <span className="underline">
                      {record?.user_name} <br />
                      {record?.user_id ? `A${record?.user_id}` : ''}
                    </span>
                  )}
                </Link>
              ) : (
                <>
                  {record?.user_name === null ? (
                    <span>{record?.user_id ? `A${record?.user_id}` : ''}</span>
                  ) : (
                    <span>
                      {record?.user_name} <br />
                      {record?.user_id ? `A${record?.user_id}` : ''}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <span>ー</span>
          )}
        </>
      ),
    },
    {
      key: 7,
      title: '住所',
      dataIndex: 'address',
      width: '7%',
      render: (text, _) => <span>{text || 'ー'}</span>,
    },
    {
      key: 8,
      title: 'キャスト',
      dataIndex: 'cast_name',
      width: '8%',
      render: (text, record) => {
        return record?.cast_id !== null ? (
          <>
            {record?.is_deleted_cast === 0 ? (
              <Link to={`${config.routes.detailCast}/${record?.cast_id}`}>
                {record?.cast_name === null ? (
                  <span className="underline">{!record?.cast_id ? '' : `B${record?.cast_id}`}</span>
                ) : (
                  <span className="underline">
                    {text} <br />
                    {!record?.cast_id ? '' : `B${record?.cast_id}`}
                  </span>
                )}
              </Link>
            ) : (
              <>
                {record?.cast_name === null ? (
                  <span>{!record?.cast_id ? '' : `B${record?.cast_id}`}</span>
                ) : (
                  <span>
                    {text} <br />
                    {!record?.cast_id ? '' : `B${record?.cast_id}`}
                  </span>
                )}
              </>
            )}
          </>
        ) : (
          <span>ー</span>
        );
      },
    },
    {
      key: 9,
      title: '指名キャスト',
      dataIndex: 'assign',
      width: '6%',
      render: (text, record) => (
        <span>{record?.cancel !== 0 || record?.deleted_at ? 0 : formatCash(text?.toString())}</span>
      ),
    },

    {
      key: 10,
      title: 'キャンセル',
      dataIndex: 'cancel',
      width: '6%',
      render: (text, _) => <span>{text !== null ? text : 'ー'}</span>,
    },
    {
      key: 11,
      title: 'クーポン利用',
      dataIndex: 'coupon',
      width: '6%',
      render: (text, record) => (
        <span>{record?.cancel !== 0 || record?.deleted_at ? 0 : formatCash(text.toString())}</span>
      ),
    },
    {
      key: 12,
      title: '暫定料金',
      dataIndex: 'total_price',
      width: '5%',
      render: (text, _) => <span>{formatCash(text.toString()) ?? 0}</span>,
    },
    {
      key: 13,
      title: 'ステータス',
      dataIndex: 'status_matching',
      width: '5%',
      render: (text, record) => {
        if (+record?.cancel !== 0 || record?.deleted_at) {
          return <span>{'キャンセル'}</span>;
        } else {
          if (text === 1 && record?.status_finish === 1) {
            return <span>{'完了'}</span>;
          }
          if (+text === 1) {
            return <span>{'確定'}</span>;
          }
          if (text === 0) {
            return <span>{'アサイン中'}</span>;
          }
        }
      },
    },
    {
      key: 14,
      title: '備考',
      dataIndex: 'request_description',
      width: '6%',
      render: (text, _) => <span>{text ?? 'ー'}</span>,
    },
    {
      key: 15,
      title: (
        <>
          強制
          <br />
          キャンセル
        </>
      ),
      dataIndex: '',
      width: '5%',
      render: (_, record) => (
        <>
          <div className="item-icon-delete" onClick={() => showModal(record?.id)}>
            {record?.status_delete === 1 ? <DeleteOutlined /> : ''}
          </div>
        </>
      ),
    },
    {
      key: 16,
      title: 'A',
      dataIndex: '',
      width: '2%',
      render: (text, record) => (
        <div className="chk-cell">
          <input name="a" type="checkbox" checked={record?.status?.a === 1} onChange={(e) => onCheckBox(e, record)} />
        </div>
      ),
    },
    {
      key: 17,
      title: 'B',
      dataIndex: '',
      width: '2%',
      align: 'center',
      render: (text, record) => (
        <div className="chk-cell">
          <input name="b" type="checkbox" checked={record?.status?.b === 1} onChange={(e) => onCheckBox(e, record)} />
        </div>
      ),
    },
    {
      key: 18,
      title: 'C',
      dataIndex: '',
      width: '2%',
      align: 'center',
      render: (text, record) => (
        <div className="chk-cell">
          <input name="c" type="checkbox" checked={record?.status?.c === 1} onChange={(e) => onCheckBox(e, record)} />
        </div>
      ),
    },
    {
      key: 19,
      title: 'D',
      dataIndex: '',
      width: '2%',
      align: 'center',
      render: (text, record) => (
        <div className="chk-cell">
          <input name="d" type="checkbox" checked={record?.status?.d === 1} onChange={(e) => onCheckBox(e, record)} />
        </div>
      ),
    },
    {
      key: 20,
      title: 'E',
      dataIndex: '',
      width: '2%',
      align: 'center',
      render: (text, record) => (
        <div className="chk-cell">
          <input name="e" type="checkbox" checked={record?.status?.e === 1} onChange={(e) => onCheckBox(e, record)} />
        </div>
      ),
    },
  ];

  const bookingListFooter = () => {
    return (
      <div className="btn-modal-delete-matching">
        <Button key="back" onClick={handleCancel} className="btn-back">
          一覧に戻る
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={loading}
          className="btn-delete-matching"
          onClick={handleDeleteMatching}
        >
          キャンセルする
        </Button>
      </div>
    );
  };

  return (
    <>
      {showPopup}
      <Modal
        title={''}
        open={isModalOpen}
        className="modal-delete-matching"
        closable={false}
        footer={[bookingListFooter()]}
        onCancel={handleCancel}
        closeIcon={<></>}
      >
        <h3 className="title-delete-matching">このマッチング1件をキャンセル(無効)しますか? </h3>
        <span className="delete-content">一度キャンセルを押すと元に戻せません。</span>
      </Modal>
      <div className="csv-block">
        <ExportCSV target="_blank" data={dataCSV} fileName="booking_list_export" textButton="CSV出力" />
        {/* <CSVLink target="_blank" data={dataCSV} filename={`booking_list_export.csv`}>
          <button>CSV出力</button>
        </CSVLink> */}
      </div>
      <Table
        rowKey="key"
        bordered={true}
        scroll={{ x: 2000 }}
        columns={columns}
        loading={tableLoading(loading)}
        pagination={false}
        dataSource={formattedListMatching}
        rowClassName={(record, _): string => (record.key % 2 !== 0 ? '' : 'row-color')}
      />
      <Space style={{ width: '100%', marginTop: '2rem' }} direction="vertical" align="end">
        <Pagination
          onChange={handlePagination}
          current={Number(searchParams.get('page'))}
          showSizeChanger={false}
          pageSize={listMatching?.per_page || 10}
          total={listMatching?.total}
        />
      </Space>
    </>
  );
};

export default BookingList;
