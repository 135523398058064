export  const transportationItems = [
    {
      id: 1,
      title: '徒歩',
    },
    {
      id: 2,
      title: 'バス',
    },
    {
      id: 3,
      title: 'タクシー',
    },
  ];