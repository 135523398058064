export const salaryItems = [
    {
        id: 1,
        title: "1万円〜5万円"
    },
    {
        id: 2,
        title: "5万円〜15万円"
    },
    {
        id: 3,
        title: "15万円以上"
    },
]