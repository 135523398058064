import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const newConfig: InternalAxiosRequestConfig<any> = config;
    const token =
      localStorage.getItem('access_token') === null ? 'null' : JSON.parse(localStorage.getItem('access_token')!);

    if (token && token !== 'undefined' && token !== 'null') {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },

  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (response && response.data && response.data.data && response.data.data.rows) {
      return response.data.data.rows;
    }

    if (response && response.data) {
      return response.data;
    }

    return response;
  },

  async (errors) => {
    if (errors.response?.status === 401 && errors.response?.data?.message === 'Unauthenticated.') {
      localStorage.clear();
      const currentUrl = window.location.href;
      var host = window.location.protocol + '//' + window.location.host;

      if (currentUrl.includes('user')) {
        window.location.replace(`${host}/user/login`);
      }
      if (currentUrl.includes('cast')) {
        window.location.replace(`${host}/cast/login`);
      }
      if (currentUrl.includes('admin')) {
        window.location.replace(`${host}/admin-dashboard/login`);
      }
    }

    if (errors.response?.status === 403 && errors.response?.data?.data?.message === 'Unauthorized action') {
      localStorage.clear();
      const currentUrl = window.location.href;
      var host = window.location.protocol + '//' + window.location.host;
      if (currentUrl.includes('user')) {
        window.location.replace(`${host}/user/login`);
      }
      if (currentUrl.includes('cast')) {
        window.location.replace(`${host}/cast/login`);
      }
      if (currentUrl.includes('admin')) {
        window.location.replace(`${host}/admin-dashboard/login`);
      }
    }

    return Promise.reject(errors);
  },
);

export default axiosClient;
