import Logout from '.';
import HelmetComp from '../../../../components/helmet/Helmet';
import { title } from '../../../../utils/matchingTitle';

export default function CustomerLogout() {
  return (
    <>
      <HelmetComp user title={title.customerLogout} />
      <Logout isCustomer />
    </>
  );
}
