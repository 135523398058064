/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { Button, Form, FormInstance, Modal } from 'antd';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { title } from '../../../utils/matchingTitle';

import HelmetComp from '../../../components/helmet/Helmet';
import image from '../../../assets/images/index';
import config from '../../../config';

const dataModalDefault: any = [
  {
    typeRequest: '予約についてのお問い合わせ',
  },
  {
    typeRequest: '確定済の予約についてのお問い合わせ',
  },
  {
    typeRequest: 'サービス全般についてのお問い合わせ',
  },
  {
    typeRequest: '業務委託費、振込口座に関するお問い合わせ',
  },
  {
    typeRequest: '上記以外の問い合わせ',
  },
];

export default function CastQuestion() {
  const navigate: NavigateFunction = useNavigate();
  const [formContact]: [FormInstance] = Form.useForm();

  // HOOK STATE
  const [dataModal] = useState(dataModalDefault);
  const [showService, setShowService]: [boolean, React.Dispatch<any>] = useState(false);

  return (
    <>
      <HelmetComp cast title={title.castQuestion} />
      <Modal
        footer={false}
        open={showService}
        onCancel={(): void => {
          setShowService(false);
        }}
        title={
          <div>
            <h2 className="head-modal-title">お問合せを選択してください。</h2>
          </div>
        }
        closeIcon={
          <>
            <img src={image.iconClose} alt="Error" />
          </>
        }
      >
        {/* ADD DATA MODAL*/}
        {dataModal &&
          dataModal?.length > 0 &&
          dataModal?.map((item: any, index: number) => {
            return (
              <p
                key={index}
                onClick={(): void => {
                  formContact.setFieldValue('type_request', item.typeRequest);
                  formContact.setFields([
                    {
                      name: 'type_request',
                      errors: undefined,
                    },
                  ]);
                  setShowService(false);
                }}
              >
                {item.typeRequest}
              </p>
            );
          })}
      </Modal>
      <div className="block-question container-680">
        <div className="content">
          <div className="menu-content">
            <div className="head-title">
              <div className="icon">
                <img src={image.iconQuestion} alt="" />
              </div>
              <h2 className="item-title-question">よくあるご質問</h2>
            </div>
            <div className="question-action">
              <div className="question-action-item">
                困ったことがあれば、
                <br />
                こちらの「よくあるご質問」をご覧ください。
                <br />
                キャストに関する質問と回答をご紹介しています。
              </div>
              <button type="button" className="btn ct-allow">
                <a href="http://www.epais.co.jp/cast/faq" target="blank" rel="noopener">
                  よくあるご質問
                </a>
              </button>
            </div>
            <div className="head-title">
              <div className="icon">
                <img src={image.iconPhone} width="51px" alt="" />
              </div>
              <h2 className="item-title-question">お電話によるお問い合わせ</h2>
            </div>
            <div className="phone-item">
              <span>
                お問い合わせの際は、番号をよくお確かめの上おかけください。
                <br />
                緊急時以外は、基本的にお問い合わせフォームよりご連絡を
                <br />
                お願いいたします。
              </span>
              <span>
                TEL : <a href="tel:07023101177">070-2310-1177</a>
              </span>
              <span className="detail-tel">【 受付時間 】 10:00〜17:00（土日祝は休み）</span>
            </div>
            <div className="head-title">
              <h2 className="">お問い合わせ</h2>
            </div>
            <div className="form-response">
              <span>
                以下のボタンよりお問い合わせフォームに移動し、
                <br />
                必要事項を記入の上、お問い合わせください。
              </span>
            </div>
            <div className="block-question-btn">
              <Form.Item>
                <Button className="btn" onClick={() => navigate(config.routes.castDashboard)}>
                  戻る
                </Button>
              </Form.Item>
              <Form.Item>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScPoMGdb0BJD7dcHDpaREZ9e88TZn0vNh3mkzkqC9K5eywOeg/viewform"
                  target="_blank"
                  rel="noopener"
                >
                  <Button className="btn ct-allow" htmlType="button">
                    {`問い合わせる`}
                  </Button>
                </a>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
