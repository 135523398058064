import { Modal } from 'antd';
import React, { Dispatch } from 'react';

interface ModalProps {
  type: string;
  showModalWarning: boolean;
  setShowModalWarning: Dispatch<any>;
}

const PRIMARY_TYPE = 'primary';
const SECOND_TYPE = 'secondary';
const THIRD_TYPE = 'third';

const CastModal = ({ type, showModalWarning, setShowModalWarning }: ModalProps) => {
  const modalSetting: any = {
    footer: false,
    centered: true,
    closable: false,
    className: 'modal-emotion',
  };

  let contentModal = <></>;

  if (type === PRIMARY_TYPE) {
    contentModal = (
      <div className="text-group">
        <div className="text-title">
          <span>トレーニングが完了していないため</span>
          <br />
          <span>サービスの利用が限定されています。</span>
        </div>
        <div className="text-desc">
          <span>トレーニングが完了していない場合、</span>
          <br />
          <span>シフト登録などの各サービスを</span>
          <br />
          <span>ご利用いただくことができません。</span>
          <br />
          <span>完了までお待ちください。</span>
        </div>
      </div>
    );
  }

  if (type === SECOND_TYPE) {
    contentModal = (
      <div className="text-group">
        <div className="text-title">
          <span>面談が完了していないため</span>
          <br />
          <span>サービスの利用が限定されています。</span>
        </div>
        <div className="text-desc">
          <span>面談が完了していない場合、</span>
          <br />
          <span>シフト登録などの各サービスを</span>
          <br />
          <span>ご利用いただくことができません。</span>
          <br />
          <span>完了までお待ちください。</span>
        </div>
      </div>
    );
  }

  if (type === THIRD_TYPE) {
    contentModal = (
      <div className="text-group">
        <div className="text-title">
          <span>シフト登録しました</span>
        </div>
      </div>
    );
  }

  const handleCloseModal = () => {
    setShowModalWarning(false);
  };

  return (
    <>
      <div className="cast-modal">
        <Modal open={showModalWarning} {...modalSetting} onCancel={handleCloseModal}>
          <div className={`cast-modal-content${type === THIRD_TYPE ? ' success' : ''}`}>
            {contentModal}
            <button className="btn btn-small btn-check back-btn-cast" onClick={handleCloseModal}>
              閉じる
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CastModal;
