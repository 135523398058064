import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import confirmImage from '../../assets/images/mockup/success-image.png';
import StatusBarCustomer from '../status/statusbarCustomer';
import image, { images } from '../../assets/images/index';
import StatusBar from '../status/statusBar';
import config from '../../config';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/services/authSlice';
import { getLocalStorage, setLocalStorage } from '../../helper/common';
import { USER_ROLE } from '../../utils/userRole';
import { alertFail, alertSuccess } from '../../helper/common';
import { notification } from 'antd';

const CAST_ROLE: number = 3;
interface SuccessActionType {
  header?: any;
  title?: string;
  textDescription?: any;
  customer?: boolean;
  editCustomer?: boolean;
  castEdit?: boolean;
  castRegister?: boolean;
  deleteCast?: boolean;
  bookingSuccess?: boolean;
  surveySuccess?: boolean;
  noImage?: boolean;
  mail?: boolean;
  deleteAccount?: boolean;
  isReBooking?: boolean;
  deleteBookingCustomer?: boolean;
  isLogin?: boolean;
  register?: boolean;
  forgotPassword?: boolean;
  isCancelBooking?: boolean;
  isErrorBooking?: boolean;
  isNoteBooking?: boolean;
}
export default function SuccessAction({
  header,
  title,
  textDescription,
  customer = false,
  deleteAccount = false,
  editCustomer = false,
  castEdit = false,
  mail = false,
  castRegister = false,
  deleteCast = false,
  bookingSuccess = false,
  surveySuccess = false,
  noImage = false,
  isReBooking = false,
  deleteBookingCustomer = false,
  isLogin = false,
  register = false,
  forgotPassword = false,
  isCancelBooking = false,
  isErrorBooking = false,
  isNoteBooking = false,
}: SuccessActionType) {
  const navigate: NavigateFunction = useNavigate();
  let path: string = '';
  const { pathname }: any = useLocation();
  const classContainerDeleteBooking = deleteBookingCustomer ? 'delete-booking-customer' : '';

  if (customer) {
    path = config.routes.loginCustomer;
  }
  if (castEdit) {
    path = config.routes.castDashboard;
  }
  if (castRegister) {
    path = config.routes.login;
  }
  if (editCustomer) {
    path = config.routes.customerDashboard;
  }
  if (deleteCast) {
    path = config.routes.login;
  }
  if (deleteCast && customer) {
    path = config.routes.loginCustomer;
  }
  if (bookingSuccess) {
    path = config.routes.customerDashboard;
  }
  if (isReBooking) {
    path = config.routes.customerDashboard;
  }
  const dispatch = useDispatch();
  const dataUser = getLocalStorage('usrdt');
  const [api, showPopup]: any = notification.useNotification();

  const user = { email: dataUser?.email, password: dataUser?.password, role: dataUser?.role };

  const handleLogin = async (): Promise<void> => {
    try {
      if (dataUser) {
        const res = await dispatch(login(user));
        if (res?.payload?.status === 'success') {
          if (pathname.state) {
            setLocalStorage('user', res?.payload?.data?.user);
            navigate(`${config.routes.newPassword}${pathname.state.search}`);
          } else {
            alertSuccess(api, 'ログインが成功しました。');

            setLocalStorage('user', res?.payload?.data?.user);
            setTimeout((): void => {
              if (res?.payload?.data?.user?.role === CAST_ROLE) {
                navigate(config.routes.castDashboard);
              }
              if (res?.payload?.data?.user?.role === USER_ROLE) {
                navigate(config.routes.customerDashboard);
              }
            }, 500);
            localStorage.removeItem('usrdt');
          }
        } else {
          alertFail(api, 'ログインが失敗しました。');
        }
      }
    } catch (error) {}
  };

  return (
    <>
      {showPopup}
      <div
        className={
          deleteAccount
            ? `confirm-container container-630 delete-account-container ${classContainerDeleteBooking}`
            : `confirm-container container-630 ${classContainerDeleteBooking}`
        }
      >
        {header ? (
          <>
            {!customer ? (
              <div className="mb-img-success">
                <StatusBar page1={true} page2={true} page3={true} />
              </div>
            ) : (
              <div className={pathname === '/user/signup/complete' ? '' : 'mb-img-success'}>
                <StatusBarCustomer page1={true} page2={true} page3={true} />
              </div>
            )}
          </>
        ) : (
          ''
        )}
        <div className="confirm">
          {noImage ? (
            ''
          ) : (
            <img src={mail ? images.iconDove : !surveySuccess ? confirmImage : images?.iconSurveyDone} alt="" />
          )}
          <div className={`confirm-content-container ${deleteAccount ? 'block-delete' : ''}`}>
            <h1 className="head-title">{title}</h1>
            <span>{textDescription}</span>
            {isNoteBooking ? (
              <p className="note-booking">
                決済に関する注意事項：
                <a href="https://www.epais.co.jp/faq/" target="_blank" rel="noopener">
                  FAQその他
                </a>
                をご確認ください
              </p>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* Display if not Cancel Booking or Error Booking when Payment */}
        {!isCancelBooking && !isErrorBooking ? (
          <div className={`${noImage ? 'warning-block' : ''} confirm-btn-container`}>
            {bookingSuccess ? (
              <button
                className={'btn cr-allow btn-change-service'}
                onClick={(): void => {
                  forgotPassword
                    ? customer
                      ? navigate(config.routes.loginCustomer)
                      : navigate(config.routes.login)
                    : navigate(config.routes.customerScheduleService);
                  localStorage.removeItem('usrfbk');
                }}
              >
                他のサービスを予約
              </button>
            ) : (
              ''
            )}

            {register ? (
              <button
                className="btn btn-confirm"
                onClick={(): void => {
                  handleLogin();
                }}
              >
                <img src={image.iconUser} alt="" />
                <span>マイページTOPへ</span>
              </button>
            ) : forgotPassword ? (
              <button
                className="btn cr-allow"
                onClick={(): void => {
                  navigate(path);
                  localStorage.removeItem('usrfpw');
                }}
              >
                <span> ログイン</span>
              </button>
            ) : (
              <button
                className="btn btn-confirm"
                onClick={(): void => {
                  navigate(path);
                }}
              >
                {isLogin ? <img src={image.iconUser} alt="" /> : ''}
                <span> {deleteCast && deleteAccount ? 'ログインページへ' : 'マイページTOPへ'}</span>
              </button>
            )}
          </div>
        ) : (
          ''
        )}

        {/* Display if is cancel booking or error booking when payment */}
        {isCancelBooking || isErrorBooking ? (
          <div className={`${noImage ? 'warning-block' : ''} confirm-btn-container`}>
            {bookingSuccess ? (
              <button
                className={'btn cr-allow btn-change-service'}
                onClick={(): void => {
                  forgotPassword
                    ? customer
                      ? navigate(config.routes.loginCustomer)
                      : navigate(config.routes.login)
                    : navigate(config.routes.customerScheduleService);
                  localStorage.removeItem('usrfbk');
                }}
              >
                再予約
              </button>
            ) : (
              ''
            )}

            {register ? (
              <button
                className="btn btn-confirm"
                onClick={(): void => {
                  handleLogin();
                }}
              >
                <img src={image.iconUser} alt="" />
                <span>マイページTOPへ</span>
              </button>
            ) : forgotPassword ? (
              <button
                className="btn cr-allow"
                onClick={(): void => {
                  navigate(path);
                  localStorage.removeItem('usrfpw');
                }}
              >
                <span> ログイン</span>
              </button>
            ) : (
              <button
                className="btn btn-confirm"
                onClick={(): void => {
                  navigate(path);
                }}
              >
                {isLogin ? <img src={image.iconUser} alt="" /> : ''}
                <span> {deleteCast && deleteAccount ? 'ログインページへ' : 'マイページTOPへ'}</span>
              </button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
