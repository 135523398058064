import HelmetComp from '../../../../../components/helmet/Helmet';
import SuccessAction from '../../../../../components/successAction';
import { title } from '../../../../../utils/matchingTitle';

const CustomerBookingCancel = () => {
  const props: any = {
    title: '依頼予約は失敗しました。',
    textDescription: (
      <>
        クレジットカードの状況をご確認の上、
        <br />
        再度予約手続きをお願いいたします。
      </>
    ),
    bookingSuccess: true,
    isLogin: true,
    mail: true,
    isCancelBooking: true,
  };
  return (
    <>
      <HelmetComp user title={title.customerBookingCancel} />
      <SuccessAction {...props} />
    </>
  );
};

export default CustomerBookingCancel;
