import { Navigate, Outlet } from 'react-router-dom';
import config from '../config';

const ProtectedRoutes = (): any => {
  // REDUCERS

  const currentUrl = window.location.href;
  const user: string | null = localStorage.getItem('access_token');

  if (currentUrl.includes('user')) {
    return user === null ? <Navigate to={config.routes.loginCustomer} /> : <Outlet />;
  }
  if (currentUrl.includes('cast')) {
    return user === null ? <Navigate to={config.routes.login} /> : <Outlet />;
  }
};

export default ProtectedRoutes;
