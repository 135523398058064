import { Helmet } from 'react-helmet-async';

interface HelmetProps {
  title: string;
  cast?: boolean;
  user?: boolean;
}

const HelmetComp = ({ title, cast = false, user = false }: HelmetProps) => {
  let lastTitle = ``;
  let prefix_title_cast = `｜キャスト｜Epais`;
  let prefix_title_user = `｜顧客｜Epais`;
  let prefix_forgot = `｜Epais`;

  if (cast) {
    lastTitle = `${title}${prefix_title_cast}`;
  }

  if (user) {
    lastTitle = `${title}${prefix_title_user}`;
  }
  if (!cast && !user) {
    lastTitle = `${title}${prefix_forgot}`;
  }
  return (
    <Helmet>
      <title>{lastTitle}</title>
    </Helmet>
  );
};

export default HelmetComp;
