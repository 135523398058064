export const buildingHeightItems = [
  {
    id: 1,
    title: '平屋',
  },
  {
    id: 2,
    title: '2階建て',
  },
  {
    id: 3,
    title: '3階建て',
  },
  {
    id: 4,
    title: '4階建て',
  },
  {
    id: 5,
    title: '4階建て以上',
  },
];
