import _ from 'lodash';
import Calendar from 'react-calendar';

import { Dispatch } from 'redux';
import { notification, Spin } from 'antd';
import { add, isSameDay, isSameMonth, lastDayOfMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

// Import React Calendar
import 'react-calendar/dist/Calendar.css';
import CheckboxCustom from '../../../../components/checkboxCustom';
import CastModal from '../../../../components/castModal/castModal';
import HelmetComp from '../../../../components/helmet/Helmet';
import image from '../../../../assets/images/index';
import config from '../../../../config';

import { castHourArrayItems } from '../../../../utils/castHourArrayItems';
import { repeatSettingItems } from '../../../../utils/repeatSettingItems';
import { alertFail, alertSuccess } from '../../../../helper/common';
import { title } from '../../../../utils/matchingTitle';
import { useDate } from '../../../../hooks/useDate';
import { castApi } from '../../../../api';
import {
  deleteCastService,
  getAllJob,
  getJobById,
  resetHourIdActiveMatching,
} from '../../../../redux/services/castSlice';
import { dayItems } from '../../../../utils/dayItems';
import { antIcon } from '../../../../App';
import { MESSAGE } from '../../../../utils/textAlert';
import { DAY_CANNOT_REPEAT } from '../../../../utils/dayCannotRepeatSetting';

const hourOptions: any = [
  {
    id: 1,
    title: 'AMを選択',
    active: false,
  },
  {
    id: 2,
    title: 'PMを選択',
    active: false,
  },
  {
    id: 3,
    title: 'All Time',
    active: false,
  },
];

const monthNow: number = new Date().getMonth();
const DATE_CAN_SCHEDULE = 15;

const RegisterSchedule = () => {
  const dispatch: Dispatch = useDispatch();
  const dateStart: Date = useDate('start');
  const navigate: NavigateFunction = useNavigate();
  const [api, showPopup]: any = notification.useNotification();

  // HOOK STATE
  const [value, setValue]: [any, React.Dispatch<any>] = useState<any>(null);
  const [loading, setLoading]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [checkEdit, setCheckEdit]: [boolean, React.Dispatch<any>] = useState<any>(false);
  const [repeatSetting, setRepeatSetting]: [any, React.Dispatch<any>] = useState<any>(null);
  const [showShift, setShowShift]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [hourArray, setHourArray]: [any, React.Dispatch<any>] = useState<any>(castHourArrayItems);
  // const [showCloseIcon, setShowCloseIcon]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [montbCalendar, setMonthCalendar]: [number, React.Dispatch<any>] = useState<number>(monthNow);
  const [hourArrayOption, setHourArrayOption]: [any, React.Dispatch<any>] = useState<any>(hourOptions);
  const [checkDisableRepeat, setCheckDisableRepeat]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [checkActiveRepeat, setCheckActiveRepeat]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [showModalWarning, setShowModalWarning]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [checkChooseHour, setCheckChooseHour]: [boolean, React.Dispatch<any>] = useState<boolean>(false);
  const [isPrev, setIsPrev] = useState<boolean>(false);
  const [isActiveRepeatSetting, setIsActiveRepeatSetting]: [any, React.Dispatch<any>] = useState<any>(false);
  const [isNext, setIsNext] = useState<boolean>(true);
  const [selectedHourOption, setSelectedHourOption]: [boolean, React.Dispatch<any>] = useState<boolean>(false);

  // REDUCER
  const { castJobActive, castJobById, castJobs } = useSelector((state: any) => state.castReducer);

  // HOOK EFFECT

  useEffect((): void => {
    dispatch(getAllJob());
  }, [dispatch]);

  useEffect((): void => {
    if (!value) {
      setCheckChooseHour(true);
    }
    if (value) {
      setCheckChooseHour(false);
    }
  }, [value]);

  useEffect((): void => {
    if (castJobById.status === 'success') {
      const tmpHourArray = hourArray?.map((hour: any) => {
        const isSameValue = castJobById?.data?.find((jobItem: any) => {
          const jobItemValue = {
            start_time: jobItem?.start_time,
            end_time: jobItem?.end_time,
          };
          return _.isEqual(jobItemValue, hour.value);
        });

        if (isSameValue && isSameValue?.status_matching === 0) {
          return {
            ...hour,
            active: true,
            matchingStatus: false,
          };
        }
        if (isSameValue && isSameValue?.status_matching === 1) {
          return {
            ...hour,
            active: true,
            matchingStatus: true,
          };
        } else {
          return {
            ...hour,
            active: false,
            matchingStatus: false,
          };
        }
      });

      setHourArray(tmpHourArray);
      setRepeatSetting(castJobById?.data[0]?.repeat_setting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [castJobById]);

  // HANDLE ONCHANGE
  const onChange = (values: any): void => {
    if (!isSameDay(values, value)) {
      setValue(values);
      const filterCheckEdit = castJobs?.data?.filter((item: any) => isSameDay(new Date(item.date), values));
      const dateConvert: Date = new Date(values);

      const day = dateConvert.getDate();
      if (DAY_CANNOT_REPEAT.includes(day)) {
        setIsActiveRepeatSetting(true);
        setRepeatSetting([]);
      } else {
        setIsActiveRepeatSetting(false);
      }

      if (filterCheckEdit?.length !== 0 && filterCheckEdit !== undefined) {
        setCheckEdit(true);
        dispatch(
          getJobById({ date: `${dateConvert.getFullYear()}-${dateConvert.getMonth() + 1}-${dateConvert.getDate()}` }),
        );
        setCheckDisableRepeat(true);
      } else {
        const tmpHour = hourArray?.map((item: any) => {
          return {
            ...item,
            active: false,
            matchingStatus: false,
            isActive: false,
          };
        });

        setHourArray(tmpHour);
        setCheckEdit(false);
        setRepeatSetting(null);
        setCheckDisableRepeat(false);
        dispatch(resetHourIdActiveMatching());
      }
      setHourArrayOption(hourOptions);
    } else {
      setValue(value);
      setShowShift(false);
      setCheckEdit(false);
      setHourArrayOption(hourOptions);
    }
  };
  const dateNow: Date = new Date();

  const date: Date = new Date(value ?? dateNow);

  const checkDateCanSchedule = (): boolean => {
    if (+dateNow.getDate() >= DATE_CAN_SCHEDULE) {
      return true;
    } else {
      return false;
    }
  };

  // CALENDAR SETTINGS
  const calendarSettings: any = {
    locale: 'ja',
    calendarType: 'US',
    next2Label: null,
    prev2Label: null,
    prevLabel: <>{isPrev && <img src={image.iconArrowLeft} className="arrow-calendar" alt="" />}</>,
    nextLabel: <>{isNext && <img src={image.iconArrowRight} className="arrow-calendar" alt="" />}</>,
  };
  const selectedDate: string =
    date.getFullYear() +
    '年' +
    (date.getMonth() + 1) +
    '月'.slice(-2) +
    date.getDate() +
    '日'.slice(-2) +
    `（${dayItems[date?.getDay()]})`;

  //HANDLE LOADING BUTTON ACTIVE, MATCHES WHEN SELECT DAY

  // RESET DATA
  const resetData = (): void => {
    const tmpHourArray = hourArray?.map((hourItem: any) => {
      return {
        ...hourItem,
        active: false,
        matchingStatus: false,
        isActive: false,
      };
    });
    setHourArray(tmpHourArray);
    setHourArrayOption(hourOptions);
    setRepeatSetting(null);
  };

  // CHECK TIME
  const checkTimeSubmit = (arr: any) => {
    const result: any = [];
    let tempArray: any = [arr[0]];

    for (let i: number = 1; i < arr.length; i++) {
      if (arr[i] === arr[i - 1] + 1) {
        tempArray.push(arr[i]);
      } else {
        result.push(tempArray);
        tempArray = [arr[i]];
      }
    }
    result.push(tempArray);
    return result;
  };

  // CHECK DATA SUBMIT
  const checkDataSubmit = (result: any) => {
    let check: boolean = true;
    for (let i: number = 0; i < result.length; i++) {
      if (result[i].length === 1) {
        check = false;
        break;
      }
    }
    return check;
  };

  const getDataSubmitSchedule = () => {
    let listHourActive: any = [];
    hourArray.forEach((hourItem: any) => {
      if (hourItem.active || hourItem.isActive) {
        listHourActive.push(hourItem.value);
      }
    });
    return listHourActive;
  };

  const handleSubmit = async (): Promise<void> => {
    let listHourActive: any = getDataSubmitSchedule();
    const year: number = date.getFullYear();
    const month: string | number = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day: string | number = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const dateSubmit: string = `${year}-${month}-${day}`;

    const submitData: any = {
      date: dateSubmit,
      array_hour: listHourActive,
      repeat_setting: repeatSetting ?? null,
    };

    try {
      if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
        alertFail(api, MESSAGE.SYSTEM_ERROR);
      } else {
        setLoading(true);
        const res: any = await castApi.registerSchedule(submitData);
        if (res.status === 'success') {
          resetData();
          dispatch(getAllJob());
          setLoading(false);
          setValue(null);
          setShowShift(false);
          setShowModalWarning(true);
        }
      }
    } catch (error) {
      alertFail(api, 'Fail');
    }
  };

  // HANDLE CALENDAR
  const handleClassname = ({ date, view }: any): string | undefined => {
    let className: string = '';
    if (view === 'month') {
      if (date.getMonth() !== montbCalendar) {
        className = 'day-not-month';
      }
      if (
        castJobActive?.find((dDate: any) => isSameDay(dDate, date) && dDate > dateStart) &&
        date.getMonth() === montbCalendar
      ) {
        className = 'selected-day';
        const sameDayInCastJob = castJobs?.data?.filter((castJobItem: any) =>
          isSameDay(new Date(castJobItem?.date), date),
        );

        const findDayNotMatching = sameDayInCastJob?.filter((dayItem: any): boolean => dayItem?.status_matching === 0);
        const findDayMatching = sameDayInCastJob?.filter((dayItem: any): boolean => dayItem?.status_matching === 1);

        //  first: have matching and not matching
        //  second: all matching
        //  third: matching full
        if (findDayNotMatching?.length !== 0 && findDayMatching?.length !== 0) {
          className += ' first';
        }

        if (findDayMatching?.length === 0 && findDayNotMatching?.length !== 0) {
          className += ' second';
        }

        if (findDayMatching?.length !== 0 && findDayNotMatching?.length === 0) {
          className += ' third';
        }
      }
    }
    return className;
  };

  const tileDisabled = ({ date, view }: any): boolean | undefined => {
    const dateNow: Date = new Date();

    if (view === 'month') {
      const sixMonthsFromNow = new Date();
      const sevenMonthsFromNow = new Date();

      sixMonthsFromNow.setMonth(dateNow.getMonth() + 6);
      sevenMonthsFromNow.setMonth(dateNow.getMonth() + 7);

      if (checkDateCanSchedule()) {
        if (
          date.getFullYear() === sevenMonthsFromNow.getFullYear() &&
          date.getMonth() === sevenMonthsFromNow.getMonth()
        ) {
          return false;
        }
      }
      if (
        (date.getFullYear() === dateNow.getFullYear() && date.getMonth() < dateNow.getMonth()) ||
        (date.getFullYear() === dateNow.getFullYear() &&
          date.getMonth() === dateNow.getMonth() &&
          date.getDate() < dateNow.getDate()) ||
        (date.getFullYear() === sixMonthsFromNow.getFullYear() && date.getMonth() > sixMonthsFromNow.getMonth()) ||
        date.getFullYear() > sixMonthsFromNow.getFullYear()
      ) {
        return true;
      }
    }
  };

  const handleSchedule = (item: any): void => {
    const tmpData = hourArray?.map((hourItem: any): any => {
      if (hourItem.id === item.id) {
        if (hourItem.isActive && !hourItem.active) {
          return {
            ...item,
            isActive: !hourItem.isActive,
          };
        }
        return {
          ...item,
          active: !hourItem.active,
        };
      } else {
        return {
          ...hourItem,
        };
      }
    });
    setHourArray(tmpData);
  };

  // HANDLE REPEAT SETTING
  const handleRepeatSetting = (value: any): void => {
    setRepeatSetting(value);
  };

  const checkSubmitStatus: boolean = useMemo(() => {
    let check: boolean = true;
    const filterValue = hourArray?.filter(
      (hourItem: any): boolean => hourItem.active === true || hourItem.isActive === true,
    );
    if (filterValue?.length === 0) {
      setCheckActiveRepeat(false);
    }
    if (filterValue?.length > 0) {
      setCheckActiveRepeat(true);
    }

    let idFilterValue: number[] = [];
    for (const filterValueKey of filterValue) {
      idFilterValue.push(filterValueKey.id);
    }
    const resultDataSubmit: any = checkTimeSubmit(idFilterValue);
    if (!checkDataSubmit(resultDataSubmit)) {
      check = false;
    }
    return check;
  }, [hourArray]);

  const handleDeleteService = async (): Promise<void> => {
    // CHECK DATE DELETE
    const filterCheckEdit = await castJobs?.data?.filter((item: any) => isSameDay(new Date(item.date), value));

    // GET LIST BUTTON ACTIVE BUT NOT MATCHING
    const listBtnActiveNotMatching = await hourArray?.filter(
      (hourItem: any) => hourItem.active && !hourItem.matchingStatus,
    );
    if (filterCheckEdit?.length >= 1 && listBtnActiveNotMatching?.length > 0) {
      const res = await dispatch(deleteCastService(filterCheckEdit[0]?.id));
      if (res?.payload?.status === 'success') {
        alertSuccess(api, 'クリアしました');
        dispatch(getAllJob());
        resetData();
        setValue(null);
      } else {
        alertFail(api, MESSAGE.DELETE_BOOKING_ERROR);
      }
    } else {
      alertFail(api, MESSAGE.DELETE_BOOKING_ERROR);
    }
  };

  // HANDLE TIME WHEN CLICK HOUR OPTIONS
  const handleHourOption = (hourOption: any): void => {
    const isBtnAM = hourOption?.id === 1;
    const isBtnPM = hourOption?.id === 2;

    const tmpHourArray = hourArray?.map((hourItem: any) => {
      if (hourOption === selectedHourOption) {
        setSelectedHourOption(null);

        return {
          ...hourItem,
          isActive: false,
        };
      } else {
        let isActiveBtn = false;
        let isActive = false;

        if (hourOption?.id === 3) {
          isActiveBtn = true;
        } else if (isBtnAM && hourItem?.id >= 1 && hourItem?.id <= 4) {
          isActiveBtn = true;
        } else if (isBtnPM && hourItem?.id >= 5 && hourItem?.id <= 11) {
          isActiveBtn = true;
        }
        if (hourItem?.active) {
          isActive = true;
        }
        const isMatched = hourItem?.matchingStatus;
        setSelectedHourOption(hourOption);

        return {
          ...hourItem,
          active: isActive || isMatched,
          isActive: isActive ? false : isActiveBtn,
        };
      }
    });
    setHourArray(tmpHourArray);
  };

  const showDeleteConfirm = () => {
    const result = window.confirm('このシフトを取り消しますか？');
    if (result) {
      handleDeleteService();
    }
  };
  const tmpAfter6Months = add(new Date(lastDayOfMonth(new Date())), { months: 6 });
  const handleOnActiveStartDate = (value: any) => {
    const currentDate = new Date();

    if (isSameMonth(new Date(value?.activeStartDate), currentDate)) {
      setIsPrev(false);
      setIsNext(true);
    }
    if (!isSameMonth(new Date(value?.activeStartDate), currentDate)) {
      setIsPrev(true);
      setIsNext(true);
    }
    if (isSameMonth(new Date(value?.activeStartDate), tmpAfter6Months)) {
      setIsNext(false);
    }
    setMonthCalendar(new Date(value?.activeStartDate).getMonth());
  };

  return (
    <>
      <HelmetComp cast title={title.castSchedule} />
      {showPopup}
      <CastModal type="third" showModalWarning={showModalWarning} setShowModalWarning={setShowModalWarning} />
      <Spin spinning={loading} indicator={antIcon} className="loading-detail">
        <div className="block-calendar container-680">
          <div className="content">
            <div className="menu-content">
              <div className="head-title">
                <div className="head-calendar">
                  <div className="icon">
                    <img src={image.iconCalendarCast} alt="Error" />
                  </div>
                  <h2 className="item-title">シフト登録・変更・削除</h2>
                </div>
              </div>
              <div className="item-des">
                <div className="calendar-description">
                  <p>
                    シフト登録が完了している日付は青色で表示されます。
                    <br />
                    削除・変更したい場合は登録した日付をタップし、「シフト
                    <br />
                    をクリアボタン」を押して再登録してください。
                  </p>
                  <div className="calendar-instructions">
                    <span>色のついた日付をタップすると登録時間を確認できます</span>
                  </div>
                </div>
                <div className="calendar-content">
                  <Calendar
                    value={value}
                    onChange={onChange}
                    {...calendarSettings}
                    tileDisabled={tileDisabled}
                    minDate={dateNow}
                    maxDate={checkDateCanSchedule() ? tmpAfter6Months : tmpAfter6Months}
                    onActiveStartDateChange={(value: any) => handleOnActiveStartDate(value)}
                    tileClassName={handleClassname}
                    formatDay={(_: string | undefined, date: Date): number | string => {
                      const tmpMonthCalendar: number = new Date(date).getMonth();
                      if (montbCalendar === tmpMonthCalendar) {
                        return date.getDate();
                      }
                      return '';
                    }}
                  />

                  {/* {showShift ? ( */}
                  <>
                    <div className="item-selected">
                      {
                        <button className={`btn-date btn-date-cast ${value ? '' : 'hidden'}`}>
                          {value === '' ? '' : selectedDate}
                        </button>
                      }
                      <div className="item-hour-line">
                        <div className="note-calendar">
                          <p>
                            {`※シフトは2時間以上連続するように選択ください`} <br />
                            {`※サービス可能時間を選択してください。`} <br />
                            {`（移動時間は考慮せずに選択ください。）`}
                          </p>
                        </div>
                        <div className="list-hour">
                          {hourArray?.map((item: any, index: any) => {
                            return (
                              <div key={index} className={`btn-hour-wrapper${item?.matchingStatus ? ' matched' : ''}`}>
                                <button
                                  onClick={(): void => handleSchedule(item)}
                                  key={item.id}
                                  className={`btn-hour${item?.active || item?.isActive ? ' btn-hour-active' : ''}${
                                    item.blocked ? ' not-allowed' : ''
                                  }`}
                                  disabled={checkChooseHour}
                                >
                                  {item.title}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                        <div className="type-hour-item">
                          {hourArrayOption?.map((hourOption: any) => {
                            return (
                              <button
                                className={`btn-type-hour${hourOption?.active ? ' active' : ''}`}
                                key={hourOption?.id}
                                onClick={() => handleHourOption(hourOption)}
                                disabled={checkChooseHour}
                              >
                                {hourOption?.title}
                              </button>
                            );
                          })}
                        </div>
                        <div className="check-hour ts-1">
                          <span>繰り返し設定</span>

                          <div className="checkbox-confirm">
                            <CheckboxCustom
                              options={repeatSettingItems}
                              onChange={handleRepeatSetting}
                              value={repeatSetting}
                              disabled={
                                (value !== null &&
                                  castJobById?.data !== undefined &&
                                  !!castJobById?.data[0]?.repeat_setting &&
                                  checkDisableRepeat) ||
                                (!checkActiveRepeat && checkChooseHour)
                                  ? true
                                  : false
                              }
                              horizontal
                              disabledOption={isActiveRepeatSetting}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  {/* ) : (
                    ''
                  )} */}
                </div>
                <div className="block-calendar-btn">
                  {/* {showShift ? ( */}
                  <button
                    className="btn btn-delete-shift"
                    onClick={(): void => {
                      if (checkEdit) {
                        showDeleteConfirm();
                      } else {
                        resetData();
                      }
                    }}
                  >
                    シフトをクリア
                  </button>

                  <button
                    className={`btn${value !== null && checkSubmitStatus ? ' ct-allow' : ' not-allowed'}`}
                    onClick={handleSubmit}
                    disabled={!(value !== null && checkSubmitStatus)}
                  >
                    確定
                  </button>
                </div>
                <div
                  className="close-icon "
                  onClick={(): void => {
                    navigate(config.routes.castDashboard);
                  }}
                >
                  <span>マイページへ戻る</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default RegisterSchedule;
