import HelmetComp from '../../../../../components/helmet/Helmet';
import SuccessAction from '../../../../../components/successAction';
import { title } from '../../../../../utils/matchingTitle';

const CustomerForgotPasswordSuccess = () => {
  const props: any = {
    title: 'パスワードが更新されました。',
    textDescription: (
      <>
        ご登録いただいたメールアドレス宛に
        <br />
        更新完了メールをお送りいたします。
      </>
    ),
    customer: true,
    forgotPassword: true,
  };
  return (
    <>
      <HelmetComp user title={title.customerEditPasswordSuccess} />
      <SuccessAction {...props} />
    </>
  );
};

export default CustomerForgotPasswordSuccess;
