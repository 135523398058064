/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import React from 'react';

import type { ColumnsType } from 'antd/es/table';

import { Pagination, Space, Table } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import { exportCSVCast, exportCSVCastAllShift, getListCast } from '../../../redux/services/adminSlice';
import { rankCastItems } from '../../../utils/rankCastItems';
import { tableLoading } from '../customer/customerList';

import config from '../../../config';
import ExportCSV from '../../../components/ExportCSV/ExportCSV';
import { formatCash } from '../../../helper/common';

const DEFAULT_PAGE: string = '1';
const DEFAULT_SIZE: string = '10';

const CastList = () => {
  const dispatch: Dispatch<any> = useDispatch();

  // PARAMS
  const [searchParams, setSearchParams]: any = useSearchParams({
    page: DEFAULT_PAGE,
    page_size: DEFAULT_SIZE,
  });

  // HOOK STATE
  const [dataCSV, setDataCSV] = useState<any>([]);
  const [dataCastAllShiftCSV, setDataCastAllShiftCSV] = useState<any>([]);
  const [listCastData, setListCastData]: [any, React.Dispatch<any>] = useState<any>([]);

  // REDUCER
  const { listCast, loading, listCastExportCSV, listCastAllShiftExportCSV } = useSelector(
    (state: any) => state.adminReducer,
  );

  // HOOK EFFECT
  useEffect(() => {
    dispatch(exportCSVCast());
    dispatch(exportCSVCastAllShift());
  }, []);

  useEffect(() => {
    if (!searchParams.get('page') || searchParams.get('page') === '0') {
      setSearchParams({
        page: DEFAULT_PAGE,
      });
    } else {
      dispatch(getListCast(Number(searchParams.get('page'))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams]);

  useEffect(() => {
    const dataExportCSV =
      listCastExportCSV?.map((user: any) => {
        return _.values({
          create_at: user?.created_at || '',
          id: user?.user_id || '',
          name: user?.name || '',
          address: user?.address || '',
          rank: user?.rank === '#' ? '' : user?.rank,
        });
      }) || [];

    const tmpDataExportCSV = [['登録日', 'キャストID', '氏名', '住所', 'キャストランク'], ...dataExportCSV];

    setDataCSV(tmpDataExportCSV);
  }, [listCastExportCSV]);

  useEffect(() => {
    const dataCastAllShiftExportCSV =
      listCastAllShiftExportCSV?.map((cast: any, index: number) => {
        return _.values({
          no: '\t' + (index + 1).toString(),
          type: 'キャストシフト',
          name: cast?.name || '',
          id_cast: cast?.id_cast || '',
          cast_rank: cast?.rank,
          start_time: cast?.start_time || '',
          end_time: cast?.end_time || '',
          updated_at: cast?.update_at || '',

          booking_id: cast?.id,
          order_id: cast?.order_id,
          service_id: cast?.service_id || '',
          date: cast?.date || '',
          hour: cast?.hour,
          user_name: cast?.user_name ? `${cast?.user_name}${cast?.user_id ? `-A${cast?.user_id}` : ''}` : '',
          address: cast?.address,
          cast_name: cast?.cast_name !== null ? `${cast?.cast_name}${cast?.cast_id ? `-B${cast?.cast_id}` : ''}` : '',
          assign: formatCash(cast?.assign?.toString()) ?? 0,
          cancel: cast?.cancel ?? 0,
          coupon: formatCash(cast?.coupon?.toString()) ?? 0,
          total_price: cast?.cancel !== 0 ? 0 : formatCash(cast?.total_price?.toString()) ?? 0,
          status_matching: cast?.id !== null ? (cast?.status_cast === 0 ? 'アサイン中' : '確定') : '',
          request_description: cast?.request_description || '',
          a: cast?.status?.a ?? 0,
          b: cast?.status?.b ?? 0,
          c: cast?.status?.c ?? 0,
          d: cast?.status?.d ?? 0,
          e: cast?.status?.e ?? 0,
        });
      }) || [];

    const tmpDataExportCSV = [
      [
        'No',
        '種別',
        'キャスト氏名',
        'キャストID',
        'ランク',
        'シフト開始日時',
        'シフト終了日時',
        'シフト登録・更新日時',
        '予約ID',
        '受注ID',
        '依頼内容',
        '依頼日',
        '依頼時間',
        '依頼者',
        '住所',
        'キャスト',
        '指名キャスト',
        'キャンセル',
        'クーポン利用',
        '暫定料金',
        'ステータス',
        '備考',
        'A',
        'B',
        'C',
        'D',
        'E',
      ],
      ...dataCastAllShiftExportCSV,
    ];

    setDataCastAllShiftCSV(tmpDataExportCSV);
  }, [listCastAllShiftExportCSV]);

  useEffect(() => {
    let listCastDataClone = listCast?.data;
    let newListCastDataClone = listCastDataClone?.map((item: any, index: number) => {
      return {
        key: (Number(searchParams.get('page')) - 1) * 10 + index + 1,
        ...item,
        rank: `${rankCastItems[item?.rank]?.label ?? 'ー'}`,
      };
    });
    setListCastData(newListCastDataClone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCast]);

  // COLUMNS TABLE
  const columns: ColumnsType<any> = [
    {
      title: ' ',
      dataIndex: 'key',
      width: 32,
      render: (text: string, _: any, __: number) => <span className="detail-table">{text}</span>,
    },
    {
      title: '登録日',
      dataIndex: 'created_at',
      width: 180,
      render: (text: string) => <span>{convertDate(text)}</span>,
    },
    {
      title: 'キャストID',
      dataIndex: 'id',
      width: 180,
      render: (text: string) => <span>{`B${text}`}</span>,
    },
    {
      title: '氏名',
      dataIndex: 'name',
      width: 180,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: '住所',
      dataIndex: 'address',
      width: 180,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'キャストランク',
      dataIndex: 'rank',
      width: 180,
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: 70,
      render: (text: any, record) =>
        record?.is_deleted === 0 ? (
          <span className="detail-table underline">{<Link to={`${config.routes.detailCast}/${text}`}>詳細</Link>}</span>
        ) : (
          ''
        ),
    },
  ];

  // HANDLE PAGINATION
  const handlePagination = async (page: number) => {
    setSearchParams({ page: page + '' });
  };

  // CONVERT DATE
  const convertDate = (date: any) => {
    return date?.replace(/-/g, '/');
  };

  return (
    <div className="cast-list-wrapper">
      <div className="csv-block">
        <ExportCSV
          target="_blank"
          data={dataCastAllShiftCSV}
          fileName="cast_all_shift_export"
          textButton="シフト出力"
        />
        <ExportCSV target="_blank" data={dataCSV} fileName="cast_list_export" textButton="CSV出力" />
        {/* <CSVLink target="_blank" data={dataCSV} filename={`cast_list_export.csv`}>
          <button>CSV出力</button>
        </CSVLink> */}
      </div>
      <Table
        bordered={true}
        loading={tableLoading(loading)}
        columns={columns}
        pagination={false}
        dataSource={listCastData}
        rowClassName={(record, _) => (record.key % 2 !== 0 ? '' : 'row-color')}
      />

      <Space style={{ width: '100%', marginTop: '2rem' }} direction="vertical" align="end">
        <Pagination
          onChange={handlePagination}
          pageSize={listCast?.per_page || 10}
          total={listCast?.total}
          current={Number(searchParams.get('page'))}
        />
      </Space>
    </div>
  );
};

export default CastList;
