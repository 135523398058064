export const CapacityMItems = [
    {
        id: 1,
        title: "50㎡以下"
    },
    {
        id: 2,
        title: "50〜100㎡"
    },
    {
        id: 3,
        title: "100㎡以上"
    },
]