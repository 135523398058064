export const CapacityKItems = [
    {
        id: 1,
        title: "1K"
    },
    {
        id: 2,
        title: "1DK"
    },  
    {
        id: 3,
        title: "1LDK"
    },
    {
        id: 4,
        title: "2K"
    },
    {
        id: 5,
        title: "2DK"
    },
    {
        id: 6,
        title: "2LDK"
    },
    {
        id: 7,
        title: "3DK"
    },
    {
        id: 8,
        title: "3LDK"
    },
    {
        id: 9,
        title: "4DK"
    },
    {
        id: 10,
        title: "4LDK"
    },
    {
        id: 11,
        title: "4LDK以上"
    },
]