/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import { Dispatch } from 'redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkAccessTokenEkyc } from '../../redux/services/castSlice';
import { antIcon } from '../../App';

export default function Error() {
  const dispatch: Dispatch = useDispatch();
  const { loadingCheckAccessToken } = useSelector((state: any) => state.castReducer);

  useEffect(() => {
    dispatch(checkAccessTokenEkyc());
  }, []);

  if (loadingCheckAccessToken) {
    return (
      <div className="loading-spinner">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <div className="page_404">
      404
      <br />
      Page not found
    </div>
  );
}
