import HelmetComp from '../../../../../components/helmet/Helmet';
import SuccessAction from '../../../../../components/successAction';
import { title } from '../../../../../utils/matchingTitle';

const DeleteCustomerSuccess = () => {
  const props: any = {
    title: '退会の手続きが完了しました。',
    textDescription: (
      <>
        これまで、ご利用頂きありがとうございました。
        <br />
        退会完了確認メールをお送りいたしますので、
        <br />
        必ずご確認をお願いいたします。
        <br />
        また、退会確認メールが届かない場合には、
        <br />
        大変お手数ですが、
        <br />
        サービス窓口
        <a href="mailto:customer-service@epais.co.jp" target="blank" rel="noopener">
          <span className="mail-delete-success">customer-service@epais.co.jp</span>
        </a>
        まで
        <br />
        ご連絡をお願いいたします。
        <br />
        またのご利用をお待ちしております。
      </>
    ),
    customer: true,
    noImage: true,
    deleteCast: true,
    deleteAccount: true,
  };
  return (
    <>
      <HelmetComp user title={title.customerDeleteSuccess} />
      <SuccessAction {...props} />
    </>
  );
};

export default DeleteCustomerSuccess;
