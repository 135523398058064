import HelmetComp from '../../../../../components/helmet/Helmet';
import SuccessAction from '../../../../../components/successAction';
import { SuccessActionPropsType } from '../../../../../types/commonTypes';
import { title } from '../../../../../utils/matchingTitle';

const CastForgotPasswordSuccess = () => {
  const props: any = {
    title: 'パスワードが更新されました。',
    textDescription: (
      <>
        ご登録いただいたメールアドレス宛に
        <br />
        更新完了メールをお送りいたします。
      </>
    ),
    forgotPassword: true,
    isLogin: false,
    castRegister: true,
  };
  return (
    <div>
      <HelmetComp title={title.castChangePasswordSuccess} cast />
      <SuccessAction {...props} />
    </div>
  );
};

export default CastForgotPasswordSuccess;
