export const JobItems = [
  {
    id: 1,
    title: '経営者・役員',
  },
  {
    id: 2,
    title: '会社員（管理職）',
  },
  {
    id: 3,
    title: '会社員（一般職）',
  },
  {
    id: 4,
    title: '契約社員・派遣社員',
  },
  {
    id: 5,
    title: 'パート・アルバイト',
  },

  {
    id: 6,
    title: '公務員（教職員除く）',
  },
  {
    id: 7,
    title: '教職員',
  },
  {
    id: 8,
    title: '医療関係者',
  },
  {
    id: 9,
    title: '自営業・自由業',
  },
  {
    id: 10,
    title: '士業（公認会計士・弁護士・税理士・司法書士）',
  },
  {
    id: 11,
    title: '大学生・大学院生',
  },
];
