export const MESSAGE = {
  LOGIN_SUCCESS: 'ログインが成功しました。',
  LOGIN_FAIL: 'ログインが失敗しました。',
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS: 'メールを送信するのに成功しました。',
  SEND_EMAIL_FORGOT_PASSWORD_FAIL: '送信が失敗しました。',
  CHANGE_EMAIL_FORGOT_PASSWORD_SUCCESS: 'パスワード変更が成功しました。',
  CHANGE_EMAIL_FORGOT_PASSWORD_FAIL: 'パスワード変更が失敗しました。',
  SYSTEM_ERROR: 'システムのエラー発生しました。',
  REGISTER_SUCCESS: '登録が成功しました。',
  UPDATE_INFOR_SUCCESS: '情報を変更するのに成功しました。',
  UPDATE_INFOR_FAIL: '情報を変更するのに失敗しました。',
  INFOR_INCORRECT_FORMAT: '情報が正しい形式ではありません、再確認してください。',
  CHANGE_PASSWORD_SUCCESS: 'パスワード変更が成功しました。',
  CHANGE_PASSWORD_FAIL: 'パスワード変更が失敗しました。',
  CANCEL_BOOKING_ERROR: '依頼をキャンセルするのに失敗しました。',
  DELETE_BOOKING_ERROR: 'シフトをクリアするのに失敗しました。',
  BOOKING_ERROR: 'シフトを登録するのに失敗しました。',
  UPDATE_BOOKING_ERROR: '依頼情報を更新するのに失敗しました。',
  APPLY_COUPON_SUCCESS: 'クーポンコードが適用されました。',
  APPLY_COUPON_ERROR: 'クーポンが無効です。',
};
