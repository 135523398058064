/* eslint-disable react/jsx-no-target-blank */
import { Modal } from 'antd';
import { authApi } from '../../api';
import { getLocalStorage } from '../../helper/common';

export default function ModalUrlKyc({ showChangeWarning, setShowChangeWarning }: any) {
  const statusEkyc = getLocalStorage('ekstt');

  const modalSetting: any = {
    footer: false,
    centered: true,
    closable: false,
    className: 'modal-emotion',
  };

  let first_text: any;
  let second_text: any;
  let button_text: string = '';
  first_text = '本人認証を開始いたします';
  second_text = (
    <>
      写真付き本人確認書類（たとえば免許証など）を <br />
      ご用意いただき、記載されている情報を入力ください。
      <br />
      ご用意いただき、記載されている情報を入力ください。
      <br />
      「本人認証登録」の際、登録した情報と異なる場合、
      <br />
      認証エラーになる恐れがあります。
    </>
  );
  button_text = '本人認証へ進む';

  const handleCancel = (): void => {
    setShowChangeWarning(false);
  };

  const handleUrlKyc = async () => {
    if (statusEkyc?.url_kyc) {
      const ekycParams = '?logout_ekyc=1';
      try {
        await authApi.logout(ekycParams);
      } catch (error) {}
    }
  };

  return (
    <>
      {/* {showPopup} */}
      <div className={`emotion-modal customer-verify `}>
        <Modal open={showChangeWarning} onCancel={handleCancel} {...modalSetting}>
          <div className={`modal-container-emotion kyc-hearing-modal-success-wrapper`}>
            <div className={`text-emotion-description customer-item item-hearing-first`}>
              <h2 className="text-emotion-first ">{first_text}</h2>
              {second_text === '' ? '' : <p className={'text-emotion-second'}>{second_text}</p>}
            </div>

            <div className="btn-bottom-emotion">
              <a href={`${statusEkyc?.url_kyc ?? '#'}`} target="_blank" rel="noopener" onClick={handleUrlKyc}>
                <button className={`btn-confirm-modal btn btn-large btn-cs-new item-hearing-first`}>
                  {button_text}
                </button>
              </a>

              <button className="btn btn-small btn-check" onClick={handleCancel}>
                閉じる
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
