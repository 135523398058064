export const rankCastItems: any = [
  { key: 0, label: 'ブラックリスト' },
  {
    key: 1,
    label: '#1',
  },
  {
    key: 2,
    label: '#2',
  },
  {
    key: 3,
    label: '#3',
  },
  {
    key: 4,
    label: '#4',
  },
  {
    key: 5,
    label: '#5',
  },
  {
    key: 6,
    label: '#6',
  },
  {
    key: 7,
    label: '#7',
  },
  {
    key: 8,
    label: '#8',
  },
  {
    key: 9,
    label: '#9',
  },
  {
    key: 10,
    label: '#10',
  },
  {
    key: 11,
    label: '#11',
  },
];
