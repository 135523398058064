import Logout from '.';
import HelmetComp from '../../../../components/helmet/Helmet';
import { title } from '../../../../utils/matchingTitle';

export default function CastLogout() {
  return (
    <>
      <HelmetComp cast title={title.castLogout} />
      <Logout isCast />
    </>
  );
}
