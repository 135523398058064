import { format } from 'date-fns';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

interface ExportCSVProps {
  target: string;
  data: any;
  fileName: string;
  textButton: string;
}

const ExportCSV = ({ target, data, fileName, textButton }: ExportCSVProps) => {
  const [currentTime, setCurrentTime] = useState<string>('');

  const handleFormatTimeCSV = () => {
    setCurrentTime(format(new Date(), 'yyMMddHHmm'));
  };

  return (
    <CSVLink target={target} data={data} filename={`${fileName}_${currentTime}.csv`} onClick={handleFormatTimeCSV}>
      <button>{textButton}</button>
    </CSVLink>
  );
};

export default ExportCSV;
