export const castExperience = [
    {
        id: 1,
        title: "1〜5年"
    },
    {
        id:2,
        title: "5〜10年"
    },
    {
        id:3,
        title: "10年以上"
    }
]