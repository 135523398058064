import _ from 'lodash';

import type { ColumnsType } from 'antd/es/table';

import { Pagination, Space, Table } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { RankUserItems } from '../../../utils/rankUserItems';

import { exportCSVUser, getListUser } from '../../../redux/services/adminSlice';
import config from '../../../config';
import { antIcon } from '../../../App';
import ExportCSV from '../../../components/ExportCSV/ExportCSV';

export const tableLoading = (loading: any) => {
  return {
    spinning: loading,
    indicator: <div className="loading-detail"> {antIcon}</div>,
  };
};
const CustomerList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [dataCSV, setDataCSV] = useState<any>([]);
  let [searchParams, setSearchParams]: any = useSearchParams({
    page: '1',
    // page_size: '10',
  });

  // REDUCERS

  const { listUser, loading, listUsersExportCSV } = useSelector((state: any) => state.adminReducer);

  // HOOK EFFECT
  useEffect(() => {
    dispatch(exportCSVUser());
  }, []);

  useEffect((): void => {
    if (searchParams.get('page') === null) {
      setSearchParams({ page: '1' });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (searchParams.get('page') !== null) {
      dispatch(getListUser(searchParams));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    const dataExportCSV =
      listUsersExportCSV?.map((user: any) => {
        return _.values({
          create_at: user?.created_at || '',
          id: user?.user_id || '',
          name: user?.name || '',
          address: user?.address || '',
          rank: user?.rank || '',
        });
      }) || [];

    const tmpDataExportCSV = [['登録日', '顧客ID', '氏名', '住所', '顧客ステージ'], ...dataExportCSV];

    setDataCSV(tmpDataExportCSV);
  }, [listUsersExportCSV]);

  // HANDLE PAGINATION
  const handlePagination = (page: number) => {
    const updatedParams: any = { page: page.toString() };
    setSearchParams(updatedParams);
  };

  // HANDLE CONVERT DATE
  const convertDate = (date: any) => {
    return date?.replace(/-/g, '/');
  };

  // COLUMN TABLE
  const columns: ColumnsType<any> = [
    {
      key: 'input',
      title: ' ',
      dataIndex: 'key',
      width: 32,
      render: (text, _) => <span className="detail-table">{text}</span>,
    },
    {
      key: 'created_at',
      title: '登録日',
      dataIndex: 'created_at',
      width: 180,
      render: (text, _) => <span>{convertDate(text) || 'ー'}</span>,
    },
    {
      key: 'id',
      title: '顧客ID',
      dataIndex: 'id',
      width: 180,
      render: (text, _) => <span>{`A${text}`}</span>,
    },
    {
      key: 'name',
      title: '氏名',
      dataIndex: 'name',
      width: 180,
      render: (text, _) => <span>{text || 'ー'}</span>,
    },
    {
      key: 'address',
      title: '住所',
      dataIndex: 'address',
      width: 180,
      render: (text, _) => <span>{text || 'ー'}</span>,
    },
    {
      key: 'stage',
      title: ' 顧客ステージ',
      dataIndex: 'rank',
      width: 180,
      render: (text, _) => <span>{text || 'ー'} </span>,
    },

    {
      key: 'details',
      title: ' ',
      dataIndex: 'id',
      width: 70,
      render: (text, record) => (
        <span className="detail-table underline">
          {record?.is_deleted === 0 ? <Link to={`${config.routes.adminCustomerDetail}/${text}`}>詳細</Link> : ''}
        </span>
      ),
    },
  ];

  // HANDLE FORMAT LIST USER
  const formattedListUser =
    listUser?.length !== 0 && listUser !== undefined
      ? listUser?.data?.map((user: any, index: any) => ({
          key: (Number(searchParams.get('page')) - 1) * 10 + index + 1,
          ...user,
          rank: RankUserItems[user?.rank]?.label || 'ー',
        }))
      : [];

  return (
    <div className="admin-customer-list">
      <div className="csv-block">
        <ExportCSV target="_blank" data={dataCSV} fileName="user_list_export" textButton="CSV出力" />
        {/* <CSVLink target="_blank" data={dataCSV} filename={`user_list_export.csv`}>
          <button>CSV出力</button>
        </CSVLink> */}
      </div>
      <Table
        columns={columns}
        dataSource={formattedListUser}
        bordered={true}
        pagination={false}
        loading={tableLoading(loading)}
        rowClassName={(record, _: number): any => (record?.key % 2 !== 0 ? '' : 'row-color')}
        rowKey={'key'}
      />
      <Space style={{ width: '100%', marginTop: '2rem' }} direction="vertical" align="end">
        <Pagination
          onChange={handlePagination}
          current={Number(searchParams.get('page'))}
          pageSize={listUser?.per_page || 10}
          total={listUser?.total}
        />
      </Space>
    </div>
  );
};
export default CustomerList;
