export const hourScheduleItems: { id: number; title: string }[] = [
  {
    id: 1,
    title: '2時間',
  },
  {
    id: 2,
    title: '3時間',
  },
];
