const CARD_BRAND_CODE_AMERICANEXPRESS = 'A';
const CARD_BRAND_CODE_MASTERCARD = 'M';
const CARD_BRAND_CODE_DINNER = 'D';
const CARD_BRAND_CODE_VISA = 'V';
const CARD_BRAND_CODE_JBC = 'J';

export {
  CARD_BRAND_CODE_AMERICANEXPRESS,
  CARD_BRAND_CODE_MASTERCARD,
  CARD_BRAND_CODE_DINNER,
  CARD_BRAND_CODE_VISA,
  CARD_BRAND_CODE_JBC,
};
