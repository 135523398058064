export const availableServiceItems = [
  {
    id: 3,
    title: 'お片付けサービス',
  },
  {
    id: 2,
    title: '整理収納サービス',
  },
  {
    id: 1,
    title: '掃除代行サービス',
  },
  {
    id: 4,
    title: '料理代行サービス',
  },
];
export const availableNow = [
  {
    id: 1,
    title: '掃除代行',
  },
];
