/* eslint-disable react/jsx-no-target-blank */
import { Badge, Drawer } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { setShowDrawer } from '../../redux/services/customerSlice';
import image, { images } from '../../assets/images/index';
import { logout } from '../../redux/services/authSlice';
import ModalSuccess from '../modalSuccess/modalSuccess';
import { getLocalStorage } from '../../helper/common';
import CastModal from '../castModal/castModal';
import config from '../../config';

export default function Header() {
  const location: Location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const statusEkyc = getLocalStorage('ekstt');

  // HOOK STATE
  const [isUser, setIsUser]: [boolean | undefined, React.Dispatch<any>] = useState<boolean>();
  const [isLogin, setIsLogin]: [boolean | undefined, React.Dispatch<any>] = useState<boolean>();
  const user: string | null = localStorage.getItem('access_token');

  const [isShowNotificationSurvey, setIsShowNotificationSurvey]: [boolean, React.Dispatch<any>] =
    useState<boolean>(false);
  const [showChangeWarning, setShowChangeWarning]: any = useState(false);
  const [showCastWarning, setShowCastWarning]: any = useState(false);
  const [modalType, setModalType] = useState({
    KYC: false,
    hearing: false,
    type: '',
  });

  const trainingAuditStatus = getLocalStorage('tastt');

  // REDUCERS
  const { matchingComplete, showDrawer } = useSelector((state: any) => state.customerReducer);

  // HOOK EFFECT
  useEffect(() => {
    dispatch(setShowDrawer(false));
    if (location?.pathname?.includes('/user/')) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (user) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [user]);

  useEffect(() => {
    if (isUser) {
      setIsShowNotificationSurvey(matchingComplete?.find((item: any) => +item.status === 0) !== undefined);
    }
  }, [matchingComplete, isUser]);

  const handleLogout = async (): Promise<void> => {
    try {
      await dispatch(logout());
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');

      if (isUser) {
        navigate(config.routes.logoutCustomer);
      } else {
        navigate(config.routes.logoutCast);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // HEADER STYLES
  const headerStyle: React.CSSProperties = {
    padding: '16px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    overflow: 'hidden',
  };

  // BODY STYLES
  const bodyStyle: React.CSSProperties = {
    width: '100%',
    overflow: 'hidden',
    padding: '0',
  };

  const heightStyle: React.CSSProperties = {
    minHeight: '100vh',
    maxWidth: '100%',
    margin: '0 auto',
  };

  // WRAPPER STYLES
  const wrapperStyle: React.CSSProperties = {
    position: 'relative',
    maxWidth: '1010px',
    margin: '0 auto',

    boxShadow: 'none',
  };

  // DRAWER STYLES
  const drawerStyle = {
    transform: 'translateX(0%)', // Điểm bắt đầu trượt của Drawer, ở đây là 100% width từ phải
    backgroundColor: 'rgb(217, 200, 161, 0.2)',
  };

  const handleEkycAndHearing = () => {
    if (statusEkyc?.status_ekyc === 0 && statusEkyc?.status_hearing === 0) {
      setModalType({
        KYC: true,
        hearing: false,
        type: 'primary',
      });
      setShowChangeWarning(true);
      return false;
    }
    if (statusEkyc?.status_ekyc === 1 && statusEkyc?.status_hearing === 0) {
      setModalType({
        KYC: true,
        hearing: false,
        type: 'second',
      });
      setShowChangeWarning(true);
      return false;
    }
    if (statusEkyc?.status_ekyc === 2 && statusEkyc?.status_hearing === 0) {
      setModalType({
        KYC: false,
        hearing: true,
        type: 'primary',
      });
      setShowChangeWarning(true);
      return false;
    }
    if (statusEkyc?.status_ekyc === 0 && statusEkyc?.status_hearing === 2) {
      setModalType({
        KYC: true,
        hearing: false,
        type: 'primary',
      });
      setShowChangeWarning(true);
      return false;
    }
    if (statusEkyc?.status_ekyc === 2 && statusEkyc?.status_hearing === 1) {
      setModalType({
        KYC: true,
        hearing: false,
        type: 'sixth',
      });
      setShowChangeWarning(true);
      return false;
    }
    if (statusEkyc?.status_ekyc === 2 && statusEkyc?.status_hearing === 2) {
      setModalType({
        KYC: false,
        hearing: false,
        type: '"',
      });
      return true;
    }
    // return true;
  };

  // HANDLE MODAL WARNING CAST
  const getTypeModalWarning = (): string => {
    let type = 'primary';

    if (trainingAuditStatus?.audit_status === 0 && trainingAuditStatus?.training_status === 0) {
      type = 'primary';
    }

    if (trainingAuditStatus?.audit_status === 0 && trainingAuditStatus?.training_status === 1) {
      type = 'secondary';
    }

    if (trainingAuditStatus?.audit_status === 1 && trainingAuditStatus?.training_status === 0) {
      type = 'primary';
    }

    if (trainingAuditStatus?.audit_status === 1 && trainingAuditStatus?.training_status === 1) {
      type = 'ok';
    }
    return type;
  };

  return (
    <header className="header-container-client">
      {isLogin ? (
        <a href="https://www.epais.co.jp/" target="blank" rel="noopener">
          <img src={images.iconLogo} alt="Error" className="logo-client" />
        </a>
      ) : (
        <img src={images.iconLogo} alt="Error" className="logo-client" />
      )}

      <ModalSuccess
        KYC={modalType.KYC}
        hearing={modalType.hearing}
        type={modalType.type}
        showChangeWarning={showChangeWarning}
        setShowChangeWarning={setShowChangeWarning}
        statusEkyc={statusEkyc}
      />
      <CastModal
        type={getTypeModalWarning()}
        showModalWarning={showCastWarning}
        setShowModalWarning={setShowCastWarning}
      />

      <>
        {showDrawer ? (
          <img
            src={image.iconClose}
            alt="Error"
            className="icon-menu-client"
            onClick={() => {
              dispatch(setShowDrawer(false));
            }}
          />
        ) : (
          <>
            {isLogin ? (
              <img
                src={image.iconMenuHeader}
                alt="Error"
                className="icon-menu-client"
                onClick={() => {
                  dispatch(setShowDrawer(isLogin ? true : false));
                  window?.scrollTo(0, 0);
                }}
              />
            ) : (
              ''
            )}
          </>
        )}
      </>

      {showDrawer && (
        <Drawer
          rootStyle={heightStyle}
          rootClassName={isUser ? 'user' : 'cast'}
          closeIcon={<></>}
          placement={'right'}
          width={'100%'}
          size="large"
          onClose={() => dispatch(setShowDrawer(false))}
          open={true}
          headerStyle={headerStyle}
          contentWrapperStyle={wrapperStyle}
          bodyStyle={bodyStyle}
          forceRender={true}
          keyboard={true}
          maskClosable={true}
          mask={true}
          drawerStyle={drawerStyle}
          zIndex={10000}
        >
          <header className="header-container-client">
            {isLogin ? (
              <a href="https://www.epais.co.jp/" target="blank" rel="noopener">
                <img src={images.iconLogo} alt="Error" className="logo-client" />
              </a>
            ) : (
              <img src={images.iconLogo} alt="Error" className="logo-client" />
            )}

            <ModalSuccess
              KYC={modalType.KYC}
              hearing={modalType.hearing}
              type={modalType.type}
              showChangeWarning={showChangeWarning}
              setShowChangeWarning={setShowChangeWarning}
              statusEkyc={statusEkyc}
            />
            <CastModal
              type={getTypeModalWarning()}
              showModalWarning={showCastWarning}
              setShowModalWarning={setShowCastWarning}
            />
            {showDrawer ? (
              <img
                src={image.iconClose}
                alt="Error"
                className="icon-menu-client"
                onClick={() => {
                  dispatch(setShowDrawer(false));
                }}
              />
            ) : (
              <>
                {isLogin ? (
                  <img
                    src={image.iconMenuHeader}
                    alt="Error"
                    className="icon-menu-client"
                    onClick={() => {
                      dispatch(setShowDrawer(isLogin ? true : false));
                    }}
                  />
                ) : (
                  ''
                )}
              </>
            )}
          </header>
          <div className="customer-dashboard-block container-680">
            <div className="content-bot">
              {isUser ? (
                <>
                  <div
                    className="account-setting-element"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      if (handleEkycAndHearing()) {
                        navigate(config.routes.customerBooking);
                      }
                    }}
                  >
                    <span className="icon">
                      <img src={image.infoIcon} alt="" />
                    </span>
                    <span className="detail">予約内容詳細</span>
                    <span className="arrow-right">
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </div>

                  <div
                    className="account-setting-element"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      if (handleEkycAndHearing()) {
                        navigate(config.routes.customerScheduleService);
                        localStorage.removeItem('srv');
                        localStorage.removeItem('cast');
                        localStorage.removeItem('cse');
                      }
                    }}
                  >
                    <span className="icon">
                      <img src={image.iconCalendarCast} alt="" />
                    </span>
                    <span className="detail">予約依頼</span>
                    <span className="arrow-right">
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </div>

                  <div
                    className="account-setting-element"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      navigate(config.routes.customerInformation);
                    }}
                  >
                    <span className="icon">
                      <img src={image.iconInforCast} alt="" />
                    </span>
                    <span className="detail">会員情報の確認・変更</span>
                    <span className="arrow-right">
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </div>

                  <div
                    className="account-setting-element"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      if (handleEkycAndHearing()) {
                        navigate(config.routes.customerRequestHistory);
                      }
                    }}
                  >
                    <span className="icon">
                      <img src={image.iconClock} alt="" />
                    </span>
                    <span className="detail">依頼履歴</span>
                    <span className="arrow-right">
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </div>

                  <div
                    className="account-setting-element"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      if (handleEkycAndHearing()) {
                        navigate(config.routes.customerQuestions);
                      }
                    }}
                  >
                    <Badge dot={isShowNotificationSurvey}>
                      <span className="icon">
                        <img src={image.iconStar} alt="" />
                      </span>
                      <span className="detail">アンケート</span>
                    </Badge>
                    <span className="arrow-right arrow-right-survey">
                      {isShowNotificationSurvey && <span className="text-notification-survey">*未回答があります</span>}
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </div>

                  <a
                    style={{ display: 'block' }}
                    href="https://forms.gle/LzVtShsJsF4qGMgx5"
                    target="_blank"
                    rel="noopener"
                    className="account-setting-element"
                    onClick={(e) => {
                      dispatch(setShowDrawer(false));
                    }}
                  >
                    <span className="icon">
                      <img src={''} alt="" style={{ opacity: 0 }} />
                    </span>
                    <span className="detail" style={{ color: '#000' }}>
                      お問い合わせ
                    </span>
                    <span className="arrow-right">
                      <img src={image.iconRequestList} alt="" />
                    </span>
                  </a>
                  <div
                    className="account-setting-element-navigate"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      navigate(config.routes.customerDashboard);
                    }}
                  >
                    <span className="detail">マイページ TOPへ戻る</span>
                  </div>
                  <a
                    style={{ display: 'block' }}
                    href="https://epais.co.jp"
                    target="_blank"
                    rel="noopener"
                    className="account-setting-element-navigate"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      localStorage.removeItem('srv');
                      localStorage.removeItem('cast');
                      localStorage.removeItem('cse');
                    }}
                  >
                    <span className="detail">サービスサイト TOPへ戻る</span>
                  </a>
                </>
              ) : (
                <>
                  <div
                    className="account-setting-element"
                    onClick={(): void => {
                      dispatch(setShowDrawer(false));
                      if (getTypeModalWarning() === 'ok') {
                        navigate(config.routes.castScheduleInstructions);
                      } else {
                        setShowCastWarning(true);
                      }
                    }}
                  >
                    <span className="icon">
                      <img src={images.iconCalendarCast} alt="Error" />
                    </span>
                    <span className="detail">シフト登録・変更・削除</span>
                    <span className="arrow-right">
                      <img src={images.iconArrowRight} alt="Error" />
                    </span>
                  </div>

                  <div
                    className="account-setting-element"
                    onClick={(): void => {
                      dispatch(setShowDrawer(false));
                      navigate(config.routes.castInformation);
                    }}
                  >
                    <span className="icon">
                      <img src={images.iconInforCast} alt="Error" />
                    </span>
                    <span className="detail">会員情報の確認・変更</span>
                    <span className="arrow-right">
                      <img src={images.iconArrowRight} alt="Error" />
                    </span>
                  </div>
                  <div
                    className="account-setting-element"
                    onClick={(): void => {
                      dispatch(setShowDrawer(false));
                      navigate(config.routes.castQuestion);
                    }}
                  >
                    <span className="icon">
                      <img src={images.iconQuestion} alt="Error" />
                    </span>
                    <span className="detail">お問い合わせ</span>
                    <span className="arrow-right">
                      <img src={images.iconArrowRight} alt="Error" />
                    </span>
                  </div>
                  <div
                    className="account-setting-element-navigate"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                      navigate(config.routes.castDashboard);
                    }}
                  >
                    <span>マイページ TOPへ戻る</span>
                  </div>
                  <a
                    style={{ display: 'block' }}
                    href="https://epais.co.jp"
                    target="_blank"
                    rel="noopener"
                    className="account-setting-element-navigate"
                    onClick={() => {
                      dispatch(setShowDrawer(false));
                    }}
                  >
                    <span className="detail"> サービスサイト TOPへ戻る</span>
                  </a>
                </>
              )}
            </div>
            <div className="btn-account-setting">
              <button className="btn cr-allow" onClick={handleLogout}>
                ログアウト
              </button>
            </div>
          </div>
        </Drawer>
      )}
    </header>
  );
}
