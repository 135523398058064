import { NavigateFunction, useNavigate } from 'react-router-dom';
import images from '../../../../assets/images';
import config from '../../../../config';
import HelmetComp from '../../../../components/helmet/Helmet';
import { title } from '../../../../utils/matchingTitle';

const ScheduleInstructions = () => {
  const navigate: NavigateFunction = useNavigate();

  const onSchedule = (): void => {
    navigate(config.routes.castSchedule);
  };

  const onBackToDashboard = (): void => {
    navigate(config.routes.castDashboard);
  };

  return (
    <>
      <HelmetComp cast title={title.castInstructions} />
      <div className="block-calendar container-680 cast-instructions">
        <div className="content">
          <div className="menu-content">
            <div className="head-title">
              <div className="head-calendar">
                <div className="icon">
                  <img src={images.iconCalendarCast} alt="Error" />
                </div>
                <h2 className="item-title">シフト登録・変更・削除</h2>
              </div>
            </div>
            <div className="instructions-content">
              <h4 className="text-ins-bold">シフト登録方法</h4>
              <div className="second-content">
                <span>早速シフト登録を始めましょう。</span>
                <br />
                <span>「シフトを登録する」ボタンを押すとカレンダーが表示され</span>
                <br />
                <span>るので、日付をタップ、サービスを開始できる時間を選択</span>
                <br />
                <span>して登録を進めてください。</span>
                <br />
                <span>登録後のシフト変更もマイページの「シフト登録・変更・削</span>
                <br />
                <span>除」のメニューから行えます。</span>
              </div>
              <h5>ご注意</h5>
              <div>
                <span className="text-ins-bold">※シフトは2時間以上連続するように選択ください</span>
                <span className="text-ins-bold">※サービス可能時間を選択してください。</span>
                <br />
                <span className="text-ins-bold mt-3">（移動時間は考慮せずに選択ください。）</span>
              </div>
              <div className="instructions-action">
                <button className="btn ct-allow ins-btn-allow" onClick={onSchedule}>
                  シフトを登録する
                </button>
                <button className="btn ins-btn-back" onClick={onBackToDashboard}>
                  戻る
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleInstructions;
