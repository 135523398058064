import HelmetComp from '../../../../../components/helmet/Helmet';
import SuccessAction from '../../../../../components/successAction';
import { title } from '../../../../../utils/matchingTitle';

const CustomerChangePasswordSuccess = () => {
  const props: any = {
    title: 'パスワードが更新されました。',
    textDescription: (
      <>
        ご登録いただいたメールアドレス宛に
        <br />
        更新完了メールを送信いたしました。
      </>
    ),
    customer: true,
    isLogin: true,
  };
  return (
    <div>
      <HelmetComp user title={title.customerEditPasswordSuccess} />
      <SuccessAction {...props} />
    </div>
  );
};

export default CustomerChangePasswordSuccess;
