import { Button, Spin, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { alertFail, alertSuccess, creditCardImage, getLocalStorage, setLocalStorage } from '../../../../helper/common';
import { updateCustomer } from '../../../../redux/services/customerSlice';
import { title } from '../../../../utils/matchingTitle';

import HelmetComp from '../../../../components/helmet/Helmet';
import config from '../../../../config';

import { useEffect, useState } from 'react';
import { antIcon } from '../../../../App';

const ChangeCreditCard = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [api, showPopup]: any = notification.useNotification();
  const ccData = localStorage.getItem('tbk') === null ? '' : JSON.parse(localStorage.getItem('tbk')!);

  // Get data from local
  const userData = getLocalStorage('usrdt');
  const userBankData = getLocalStorage('usrbdt');
  const dateExpiredFormat = getLocalStorage('expired_date_format');

  const dateExpired: Date = new Date(`${dateExpiredFormat}-01`);
  const dateExpiredString: string = `${dateExpired?.getMonth() + 1}月 ${dateExpired?.getFullYear()}年`;

  // Hook Effect

  useEffect(() => {
    const timeId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  // HANDLE SUBMIT
  const handleSubmitEdit = async (): Promise<void> => {
    const dataEditSubmit: any = {
      name: userData?.name,
      city: userData?.city,
      note: userData?.note,
      email: userData?.email,
      gender: userData?.gender,
      street: userData?.street,
      station: userData?.station,
      password: userData?.password,
      province: userData?.province,
      capacity_m: userData?.capacity_m,
      capacity_k: userData?.capacity_k,
      profession: userData?.profession,
      description: userData?.description,
      postal_code: userData?.postal_code,
      station_time: userData?.station_time,
      name_building: userData?.name_building,
      name_furigana: userData?.name_furigana,
      card_holder: userBankData?.card_holder,
      building_type: userData?.building_type,
      transportation: userData?.transportation,
      building_height: userData?.building_height,
      password_confirmation: userData?.password_confirmation,
      dob: `${userData?.year}-${userData?.month}-${userData?.day}`,
      tokenSb: ccData?.token,
      tokenKey: ccData?.tokenKey,
    };

    try {
      const res = await dispatch(updateCustomer(dataEditSubmit));
      if (res.payload.status === 'success') {
        alertSuccess(api, 'カード情報を変更しました。');
        localStorage.removeItem('usredt');
        localStorage.removeItem('usrbdt');
        localStorage.removeItem('usrdt');
        localStorage.removeItem('expired_date_format');

        const user = getLocalStorage('user');
        if (user !== null) {
          const tmpUserData = {
            ...user,
            name: userData?.name,
          };
          setLocalStorage('user', tmpUserData);
        }
        navigate(config.routes.customerEditSuccess);
      }
    } catch (error) {
      alertFail(api, 'カード情報の変更に失敗しました。');
    }
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <div className="change-credit-card-container container-680">
      <HelmetComp user title={title.customerEditBankOnly} />
      {showPopup}
      <div className="title">
        <h2 className="head-title">クレジットカードの変更</h2>
      </div>

      <div className="content">
        {/* <div className="content-element">
          <span className="field">ご利用カード</span>
          <span className="value">{creditCardImage(ccData?.cardBrandCode)}</span>
        </div> */}
        <div className="content-element">
          <span className="field">カード番号</span>
          <span className="value">
            {userBankData?.card_number &&
              `**** **** **** ${userBankData?.card_number?.slice(userBankData?.card_number?.length - 4)}`}
          </span>
        </div>
        <div className="content-element">
          <span className="field">カード名義人</span>
          <span className="value">{userBankData?.card_holder}</span>
        </div>
        <div className="content-element">
          <span className="field">有効期限</span>
          <span className="value">{dateExpiredString}</span>
        </div>
        <div className="content-element">
          <span className="field">セキュリティコード</span>
          <span className="value">{userBankData?.security_code > 1000 ? '****' : '***'}</span>
        </div>
      </div>

      <div className="button-block">
        <Button
          className="btn"
          onClick={() => {
            setLocalStorage('isEditBankOnly', true);
            localStorage.removeItem('expired_date_format');
            navigate(config.routes.customerSignupBankEdit);
          }}
        >
          戻る
        </Button>
        <Button
          className="btn cr-allow"
          onClick={() => {
            handleSubmitEdit();
          }}
        >
          {`登録 `}
        </Button>
      </div>
    </div>
  );
};

export default ChangeCreditCard;
