export const title: any = {
  login: 'ログイン',
  castRegister: '新規会員登録',
  castRegisterCompleteCheck: '完了',
  castRegisterComplete: '仮登録完了',
  castSignUp: '会員登録',
  castSignUpBank: '会員登録',
  castConfirmRegister: '入力情報確認',
  castSignUpComplete: '完了',
  castDashboard: 'マイページ',
  calendarDetail: 'ご依頼内容詳細',
  castChangeScheduleSuccess: '完了',
  castChangeSchedule: 'キャスト変更依頼確認画面',
  castReport: 'ご依頼完了報告',
  castReportSuccess: '完了',
  castInformation: '会員情報の確認・変更',
  castDetails: '会員情報の確認',
  editCastSignUp: '会員情報の変更',
  editCastBank: '会員情報の変更',
  confirmRegisterEdit: '会員情報の確認',
  editCastSuccess: '完了',
  castEditBankOnly: '振込先情報の変更',
  castBankDetail: '振込先情報の変更',
  castLogout: 'ログアウト',
  castSendEmailChangePassword: 'パスワードの変更',
  castSendEmailChangePasswordSuccess: '仮登録完了',
  castChangePasswordSuccess: '完了',
  castDeleteAccount: '退会の手続き',
  castDeleteAccountSuccess: '完了',
  castInstructions: 'シフト登録・変更・削除',
  castSchedule: 'シフト登録・変更・削除',
  castQuestion: 'よくあるご質問',
  castQuestionComplete: '完了',
  castNewPassword: 'パスワードの変更',
  forgotPassword: 'パスワードの変更',
  forgotNewPassword: 'パスワードの変更',

  // user
  customerRegister: '新規会員登録',
  customerSendEmailComplete: '仮登録完了',
  customerSendEmailChangePasswordComplete: 'パスワード変更依頼完了',
  customerRegisterCompleteCheck: '完了',
  customerNoteRegister: '会員登録をされる前のご注意',
  customerSignUp: '会員登録',
  customerSignUpBank: '会員登録',
  customerConfirmRegister: '入力情報確認',
  customerConfirmComplete: '完了',
  customerLogin: 'ログイン',
  customerLogout: 'ログアウト',
  customerDashboard: 'マイページ',
  customerHearing: '初回ヒアリング予約',
  customerHearingConfirm: '入力情報確認',
  customerHearingSuccess: '完了',
  customerChooseService: '予約依頼',
  customerScheduleDetail: '掃除代行サービス',
  customerConfirmScheduleInfo: '入力情報確認',
  customerBookingDetail: '概算料金',
  customerBookingSuccess: '完了',
  customerBooking: '予約内容詳細',
  customerSurvey: '作業完了チェックシート',
  customerSurveySuccess: '完了',
  customerChangeBooking: '予約内容変更',
  customerReConfirmChangeBookingUpdate: '予約内容変更確認画面',
  customerReBooking: '完了',
  customerDeleteBooking: '以下の予約をキャンセルしますか？',
  customerReConfirmDelete: '予約内容変更確認画面',
  customerDeleteBookingSuccess: '完了',
  customerInforMation: '会員情報の確認・変更',
  customerDetails: '会員情報の確認',
  customerEdit: '会員情報の変更',
  customerEditBank: 'クレジットカードの変更',
  customerEditBankOnly: 'クレジットカードの変更',
  customerEditBankOnlySuccess: '完了',
  customerSendEmailChangePassword: 'パスワード変更依頼完了',
  customerNewPassword: 'パスワードの変更',
  customerEditPasswordSuccess: '完了',
  customerDeleteService: '退会の手続き',
  customerDeleteSuccess: '完了',
  customerHistory: '依頼履歴',
  customerScheduleFromHistory: '予約内容変更',
  customerQuestions: 'アンケート',
  customerQuestionsDetails: 'アンケート',
  customerQuestionSuccess: '完了',
  customerBookingError: '失敗',
  customerBookingCancel: '失敗',
  CompleteSendEmailCustomer: '仮登録完了',
};
