import HelmetComp from '../../../../components/helmet/Helmet';
import SuccessAction from '../../../../components/successAction';
import { SuccessActionPropsType } from '../../../../types/commonTypes';
import { title } from '../../../../utils/matchingTitle';

const CastReportSuccess = () => {
  const props: SuccessActionPropsType = {
    title: 'サービス完了を報告しました。',
    textDescription: (
      <>
        お疲れ様でした！
        <br />
        引き続きよろしくお願い致します。
      </>
    ),
    castEdit: true,
    isLogin: true,
  };
  return (
    <div>
      <HelmetComp cast title={title.castReportSuccess} />
      <SuccessAction {...props} />
    </div>
  );
};

export default CastReportSuccess;
