export const BuildingType = [
    {
        id: 1,
        title: "マンション（アパート・社宅を含む）"
    },
    {
        id: 2,
        title: "戸建て"
    },
    {
        id: 3,
        title: "その他"
    },

]